import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServicesModule } from "./services/services.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgBootstrapFormValidationModule } from "ng-bootstrap-form-validation";
// import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
// import {AutoCompleteModule} from 'angular-ngx-autocomplete';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { LanguageChangeModule } from "./common-modules/language-change/language-change.module";
// import { RouterModule } from "@angular/router";
import { JwtInterceptor } from "./common/http/jwt.interceptor";
import { CandidateErrorInterceptor } from "./common/http/candidate-error.interceptor";
import { EmployerAuthComponent } from "./common-modules/employer-auth/employer-auth.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SharedCandidateModule } from "./shared-candidate/shared-candidate.module";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AutocompleteComponent, AutocompleteLibModule} from 'angular-ng-autocomplete';
import { EmployerModule } from "./employer/employer.module";
import { EmployerComponent } from "./employer/employer/employer.component";
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
@NgModule({
  declarations: [AppComponent, EmployerAuthComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // MarketingModule,
    SharedCandidateModule,
    // AutocompleteLibModule,
    //     EmployerModule,
    CommonModule,
   
    RouterModule,
    ServicesModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LanguageChangeModule,

    MatSnackBarModule,
    CommonModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      // isolate: true,
    }),
    LanguageChangeModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    MatFormFieldModule ,
    MatInputModule 
  ],
  providers: [
    { provide: "googleTagManagerId", useValue: "G-ZZKF7NHWGK" },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CandidateErrorInterceptor,
      multi: true,
    },
  ],
  exports: [],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA,
  //   NO_ERRORS_SCHEMA
  // ],
  bootstrap: [AppComponent],
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
