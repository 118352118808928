import { Component, OnInit, EventEmitter } from "@angular/core";
import { CandidateAuthenticationService } from "src/app/services/candidate/auth/candidate-authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language.service";
import { EmployerAuthenticationService } from "src/app/services/employer/auth/employer-authentication.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { v4 as uuidv4 } from 'uuid'
import { LandingPageService } from "src/app/services/marketing/landing/landing-page.service";
import { MyAccountService } from "src/app/services/candidate/my-account/my-account.service";

@Component({
  selector: "app-c-home-heder",
  templateUrl: "./c-home-heder.component.html",
  styleUrls: ["./c-home-heder.component.scss"],
})
export class CHomeHederComponent implements OnInit {
  isNavigationActive: boolean = false;
  navbarToggle: Boolean = false;
  onSubs: Boolean = false;
  navItems: any = [];
  onLangChange: EventEmitter<any> = new EventEmitter();
  // CandiadateNav:any=["Post Your Resume","For Employers","About Us","Our Blog","Contact Us"
  userName: string = "";
  empUserName: string = "";
  routerLinkHome: string = "/";
  isBadge: boolean = false;
  profilePic:any = null;

  constructor(
    public authenticationService: CandidateAuthenticationService,
    public empAuth: EmployerAuthenticationService,
    public router: Router,
    public translate: TranslateService,
    public lng: LanguageService,
    private activatedRoute: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private userCount: LandingPageService,
    private candidateService: MyAccountService,
  ) {
    if (this.empAuth.isLoggedIn()) {
      this.routerLinkHome = "/employer";
    }
  }
  hideNavItem(url: string) {
    let canidateLoggedIn = this.authenticationService.isAuthenticated();
    let empLoggedIn = this.empAuth.isLoggedIn();
    if (
      (canidateLoggedIn || empLoggedIn) &&
      (url === "/" || url === "/employer")
    )
      return false;
    return true;
  }
  ngOnInit(): void {
    if (this.empAuth.isLoggedIn()) {
      this.empAuth.currentUser.subscribe((user: any) => {
        this.empUserName = user.name;
        this.profilePic = user.profile_pic
      });
    }

    if(this.authenticationService.isAuthenticated()){
        this.userName = localStorage.getItem("cuser");
        this.profilePic = localStorage.getItem("profile_pic");
     }

    this.activatedRoute.queryParams.subscribe((p: any) => {
      if (p["view"] == "subscription") {
        this.onSubs = true;
      }

      // for login and signup with linkedIn
      if (localStorage.getItem("linkedIn") && p["code"]) {
        this.authenticationService
          .linkedinLoginOrSignup(p["code"])
          .then((res) => {
            res.json().then((res) => {
              if (res.token) {
                this.authenticationService.candidateLoginProcess(res);
                this._snackbar.open(res.message, "x");
              } else {
                this._snackbar.open(res.message, "x");
              }
            });
          });
      }

      // for login and signup with google
      if (localStorage.getItem("google") && p["code"]) {
        this.authenticationService
          .googleLoginOrSignup(p["code"])
          .then((res) => {
            res.json().then((res) => {
              if (res.token) {
                this.authenticationService.candidateLoginProcess(res);
                this._snackbar.open(res.message, "x");
              } else {
                this._snackbar.open(res.message, "x");
              }
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
    
    this.getTranslatedNavbar();
    this.translate.onLangChange.subscribe((res: any) => {
      this.getTranslatedNavbar();
    });

    // for subscription badge
    this.checkForBadge();
    // this.checkForBadgeEmp();
  }

  getTranslatedNavbar() {
    let viewMode = localStorage.getItem("viewMode")
      ? localStorage.getItem("viewMode")
      : "candidate";
    this.translate.get(`${viewMode}Navbar`).subscribe((res: string) => {
      this.navItems = Object.values(res);
      this.navItems.forEach(navData => {
        if (navData.name === 'Blog') {
          navData.name = 'Blogs';
        }
        if (navData.name === 'Submit Your CV' && localStorage.getItem('viewMode') === 'candidate' && localStorage.getItem('Ctoken') !== null) {
          navData.name = 'My CV'
        }
        if (navData.name === 'My CV' && localStorage.getItem('viewMode') === 'candidate' && localStorage.getItem('Ctoken') == null) {
          navData.name = 'Submit Your CV'
        }
      });
    });
  }

  logoutAndRefresh() {
    var logout = confirm("Are you sure you want to logout?");
    if (logout) {
      this.navbarToggle = false;
      this.authenticationService.doLogout();
      const sessionId = uuidv4(); // Generate a unique session ID
      if (!sessionStorage.getItem('sessionId')) {
        sessionStorage.setItem('sessionId', sessionId); // Store the session ID in sessionStorage
      }
      this.userCount.logOutInUser();
      this.userCount.visitorIn();
      // this.socket.emit('logout', 'logout')
      // this.socket.emit('visitor', 'visitor')
      // this.router.navigate([this.router.url]);
      this.router.navigate([this.routerLinkHome]);
      this.checkForBadge();
      this.getTranslatedNavbar();

    }
  }
  logoutAndRefreshEmp() {
    var logout = confirm("Are you sure you want to logout?");
    if (logout) {
      this.navbarToggle = false;
      this.empAuth.logout();
      // this.router.navigate([this.router.url]);
      const sessionId = uuidv4(); // Generate a unique session ID
      if (!sessionStorage.getItem('sessionId')) {
        sessionStorage.setItem('sessionId', sessionId); // Store the session ID in sessionStorage
      }
      this.userCount.logOutInUser();
      this.userCount.visitorIn();
      // this.socket.emit('logout', 'logout')
      // this.socket.emit('visitor', 'visitor')
      // window.location.href = this.routerLinkHome;
      this.router.navigate([this.routerLinkHome]);

      this.checkForBadge();
    }
  }
  goToRoute(url: any) {
    this.navbarToggle = false;
    if (url == "/employer") {
      localStorage.setItem("viewMode", "employer");
    }
    if (url == "/") {
      localStorage.setItem("viewMode", "candidate");
    }
    this.getTranslatedNavbar();
    if (url == "/employejubscription") {
      return this.router.navigate(["employer"], {
        queryParams: {
          view: "subscription",
        },
      });
    }
    if (url.includes("covid")) {
      return window.open(url, "_blank");
    } else {
      this.router.navigate([url]);
    }
  }
  checkActiveUrl(url: any) {
    let routerUrl = this.router.url.split("?")[0];
    if (routerUrl == "/employer" || routerUrl == "/") {
      return false;
    }
    if (routerUrl == url) {
      return true;
    }
    return false;
  }
  scrollTo(id: any) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth" });
  }

  toggleResponsiveMenu() {
    this.navbarToggle = !this.navbarToggle;
  }

  // subscription badge methods
  checkForBadge() {
    // if (
    //   !this.authenticationService.isAuthenticated() &&
    //   this.routerLinkHome == "/"
    // ) {
    //   this.isBadge = true;
    // }
    // if (!this.empAuth.isLoggedIn() && this.routerLinkHome == "/employer") {
    //   this.isBadge = true;
    // }
  }
  onBadge() {
    this.router.navigate(["/candidate/login"]);
  }


  getViewMode() {
    return localStorage.getItem("viewMode") || "candidate";
  }

  getCurrentLanguage() {
    return this.lng.language;
  }

  toggleNavigation() {
    this.isNavigationActive = !this.isNavigationActive;
  }

  truncateUsername(): string {
    const maxChars = !this.isNavigationActive ? 4 : 16;
    const userName = this.empAuth.isLoggedIn() ? this.empUserName : this.userName;
    const truncatedUsername = userName.length > maxChars ? userName.slice(0, maxChars-2) + '...' : userName;
    return truncatedUsername;
  }
}
