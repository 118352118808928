import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {EmployerAuthenticationService} from '../../services/employer/auth/employer-authentication.service';
import {APP_URLS} from "../../routes";
import { CandidateAuthenticationService } from 'src/app/services/candidate/auth/candidate-authentication.service';
import { CandidateSignupComponent } from "../../common-modules/candidate-auth/candidate-signup/candidate-signup.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class CandidateStageGuard implements CanActivate {
  constructor(private candidateAuth: CandidateAuthenticationService,
              private router: Router,
              private _snackBar: MatSnackBar,
              ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.candidateAuth.isRegistering && !state.url.includes('register') && (this.candidateAuth.getRegistrationStage() > 0 || this.candidateAuth.getRegistrationStage() == 'phone')) {
      let currentRegStage = this.candidateAuth.getRegistrationStage();
      this.router.navigate([`candidate/register/${currentRegStage}`]);
        this._snackBar.open('In order to proceed, please finish your registration', `×`, {
            duration: 2000,
        });
      return false;
    }

    return true;
  }
}

