import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language.service";

@Component({
  selector: "app-language-change",
  templateUrl: "./language-change.component.html",
  styleUrls: ["./language-change.component.scss"],
})
export class LanguageChangeComponent implements OnInit {
  currentLang: any = "";
  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    this.currentLang = this.languageService.language;
  }
  useLanguage(language: string) {
    this.currentLang = language;
    this.languageService.language = language;
    this.languageService.changeLanguage();
    window.location.reload(); //to reload page for refetching get api with arabic content
  }

  @Input() homeFlag = false;
}
