<header>
  <div class="navbar_main">
    <div class="nav navbar_flex_item">
      <input type="checkbox" id="nav-check" [(ngModel)]="isNavigationActive" />
      <div class="nav-header">
        <div class="nav-title">
          <a [routerLink]="['/']" rel="canonical">
            <img src="../../../assets/img/hiresgulf_logo.svg" alt="" />
          </a>
        </div>
      </div>
      <div
        class="nav-links"
        *ngIf="
          !authenticationService.isAuthenticated() &&
          !empAuth.isLoggedIn() &&
          getViewMode() === 'candidate'
        "
      >
        <a [routerLink]="routerLinkHome" class="home_icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M7.59343 0.46955C7.99234 0.165614 8.48822 0 8.99936 0C9.51049 0 10.0064 0.165614 10.4053 0.46955L17.1553 5.60542C17.6889 6.0114 18 6.62648 18 7.27703V15.8601C18 16.4276 17.7629 16.9719 17.341 17.3732C16.919 17.7745 16.3467 18 15.75 18H11.25C10.9943 18 10.749 17.9034 10.5681 17.7314C10.3873 17.5594 10.2857 17.3261 10.2857 17.0829V10.663H7.71429V17.0829C7.71429 17.3261 7.61269 17.5594 7.43185 17.7314C7.25101 17.9034 7.00574 18 6.75 18H2.25C1.65326 18 1.08097 17.7745 0.65901 17.3732C0.237053 16.9719 0 16.4276 0 15.8601V7.27581C0 6.62648 0.311143 6.0114 0.844714 5.60542L7.59343 0.46955ZM9.20057 1.90148C9.14361 1.85822 9.07289 1.83466 9 1.83466C8.92711 1.83466 8.85639 1.85822 8.79943 1.90148L2.04943 7.03735C2.01161 7.06607 1.9811 7.10255 1.96018 7.14404C1.93925 7.18554 1.92845 7.231 1.92857 7.27703V15.8601C1.92857 16.0288 2.07257 16.1658 2.25 16.1658H5.78571V9.74592C5.78571 9.50268 5.88731 9.26941 6.06815 9.09741C6.24899 8.92542 6.49426 8.8288 6.75 8.8288H11.25C11.5057 8.8288 11.751 8.92542 11.9319 9.09741C12.1127 9.26941 12.2143 9.50268 12.2143 9.74592V16.1658H15.75C15.8352 16.1658 15.917 16.1336 15.9773 16.0762C16.0376 16.0189 16.0714 15.9411 16.0714 15.8601V7.27581C16.0714 7.22999 16.0605 7.18477 16.0395 7.14349C16.0186 7.10222 15.9882 7.06595 15.9506 7.03735L9.20057 1.90148Z"
              fill="#6F6F6F"
            />
          </svg>
        </a>
        <a
          [routerLink]="['/candidate', 'login']"
          routerLinkActive="router-link-active"
          >Login</a
        >

        <a
          [routerLink]="['/jobs']"
          (click)="toggleNavigation()"
          routerLinkActive="router-link-active"
          >Jobs</a
        >
        <a
          [routerLink]="['/blog']"
          (click)="toggleNavigation()"
          routerLinkActive="router-link-active"
          >Blogs</a
        >
        <a
          class="employer_btn"
          (click)="toggleNavigation()"
          [routerLink]="['/employer']"
          >Employers</a
        >
      </div>

      <div
        class="nav-links"
        *ngIf="!empAuth.isLoggedIn() && getViewMode() === 'employer'"
      >
        <a [routerLink]="['/']" class="home_icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M7.59343 0.46955C7.99234 0.165614 8.48822 0 8.99936 0C9.51049 0 10.0064 0.165614 10.4053 0.46955L17.1553 5.60542C17.6889 6.0114 18 6.62648 18 7.27703V15.8601C18 16.4276 17.7629 16.9719 17.341 17.3732C16.919 17.7745 16.3467 18 15.75 18H11.25C10.9943 18 10.749 17.9034 10.5681 17.7314C10.3873 17.5594 10.2857 17.3261 10.2857 17.0829V10.663H7.71429V17.0829C7.71429 17.3261 7.61269 17.5594 7.43185 17.7314C7.25101 17.9034 7.00574 18 6.75 18H2.25C1.65326 18 1.08097 17.7745 0.65901 17.3732C0.237053 16.9719 0 16.4276 0 15.8601V7.27581C0 6.62648 0.311143 6.0114 0.844714 5.60542L7.59343 0.46955ZM9.20057 1.90148C9.14361 1.85822 9.07289 1.83466 9 1.83466C8.92711 1.83466 8.85639 1.85822 8.79943 1.90148L2.04943 7.03735C2.01161 7.06607 1.9811 7.10255 1.96018 7.14404C1.93925 7.18554 1.92845 7.231 1.92857 7.27703V15.8601C1.92857 16.0288 2.07257 16.1658 2.25 16.1658H5.78571V9.74592C5.78571 9.50268 5.88731 9.26941 6.06815 9.09741C6.24899 8.92542 6.49426 8.8288 6.75 8.8288H11.25C11.5057 8.8288 11.751 8.92542 11.9319 9.09741C12.1127 9.26941 12.2143 9.50268 12.2143 9.74592V16.1658H15.75C15.8352 16.1658 15.917 16.1336 15.9773 16.0762C16.0376 16.0189 16.0714 15.9411 16.0714 15.8601V7.27581C16.0714 7.22999 16.0605 7.18477 16.0395 7.14349C16.0186 7.10222 15.9882 7.06595 15.9506 7.03735L9.20057 1.90148Z"
              fill="#6F6F6F"
            />
          </svg>
        </a>
        <a [routerLink]="['/employer', 'signup']">Employer Register </a>
        <a [routerLink]="['/employer', 'login']">Employer Login </a>
      </div>
      <div
        class="nav-links"
        id="candidate-links"
        *ngIf="authenticationService.isAuthenticated()"
      >
        <a
          [routerLink]="['/candidate', 'dashboard']"
          routerLinkActive="router-link-active"
          >Dashboard</a
        >
        <a (click)="logoutAndRefresh()">{{
          "header.loginDropDownTitleLogout" | translate
        }}</a>

        <div
          class="navigation-user-profile"
          [class.active]="isNavigationActive"
        >
          <div class="user-box">
            <div class="image-box">
              <!-- <img src="https://i.pravatar.cc/150?img=49" alt="avatar" /> -->
              <img src="{{ profilePic }}" alt="avatar" />
            </div>
            <p class="username">{{ truncateUsername() }}</p>
          </div>
          <button class="menu-toggle-user-profile" (click)="toggleNavigation()">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </button>
          <ul
            class="menu-user-profile"
            *ngIf="authenticationService.isAuthenticated()"
          >
            <li>
              <a
                [routerLink]="['/candidate', 'dashboard']"
                routerLinkActive="router-link-active"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a (click)="logoutAndRefresh()">
                {{ "header.loginDropDownTitleLogout" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="nav-links" id="employer-links" *ngIf="empAuth.isLoggedIn()">
        <a
          [routerLink]="['/employers', 'dashboard']"
          routerLinkActive="router-link-active"
        >
          Dashboard
        </a>
        <a (click)="logoutAndRefreshEmp()">
          {{ "header.loginDropDownTitleLogout" | translate }}
        </a>
        <div
          class="navigation-user-profile"
          [class.active]="isNavigationActive"
        >
          <div class="user-box">
            <div class="image-box">
              <!-- <img src="https://i.pravatar.cc/150?img=49" alt="avatar" /> -->
              <img src="{{ profilePic }}" alt="avatar" />
            </div>
            <!-- <p class="username">{{ empUserName | slice : 0 : 16 }} {{ " " }}</p> -->
            <p class="username">{{ truncateUsername() }}</p>
          </div>
          <button class="menu-toggle-user-profile" (click)="toggleNavigation()">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </button>
          <ul class="menu-user-profile" *ngIf="empAuth.isLoggedIn()">
            <li>
              <a
                [routerLink]="['/employers', 'dashboard']"
                routerLinkActive="router-link-active"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a (click)="logoutAndRefreshEmp()">
                {{ "header.loginDropDownTitleLogout" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="nav-btn">
        <label for="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
    </div>
    <!--------- XXX ---------------->
  </div>
</header>

<!-- old code -->
<!-- <header>
  <img *ngIf=" !authenticationService.isAuthenticated() && !empAuth.isLoggedIn() &&getViewMode() === 'candidate' "
    class="float-badge" src="../../assets/img/SIGNUP.png" alt="Subscription" (click)="onBadge()" />
  <div class="social-left" [class.mat-elevation-z8]="true">
    <a href="https://www.linkedin.com/company/hiresgulf/?viewAsMember=true" target="_blank" rel="noopener"
      style="margin-top: 20px;"><img src="../../../assets/img/icon/social media icons-04.png"
        alt="icons8-linkedin_filled" />
      <div class="social-hover">LinkedIn</div>
    </a>
    <br>
    <a href="https://www.instagram.com/hiresgulf" target="_blank" rel="noopener"><img
        src="../../../assets/img/icon/social media icons-05.png" alt="icons8-youtube_filled" />
      <div class="social-hover">Instagram</div>
    </a>
    <br>
    <a href="https://twitter.com/HiresGulf" target="_blank" rel="noopener"><img
        src="../../../assets/img/icon/social media icons-03.png" alt="icons8-twitter" />
      <div class="social-hover">Twitter</div>
    </a>
    <br>


    <a href="https://www.facebook.com/HiresGulf/?modal=admin_todo_tour" target="_blank" rel="noopener noreferrer"><img
        src="../../../assets/img/icon/social media icons-02.png" alt="icons8-facebook" />
      <div class="social-hover"> Facebook</div>

    </a>

    <br>

    <a href="https://apps.apple.com/in/app/hiresgulf/id1532042887" target="_blank" rel="noopener"><img
        src="../../../assets/img/icon/Frame 1.png" alt="icons8-linkedin_filled" />
      <div class="social-hover">App Store</div>
    </a>
    <br>
    <a href="https://play.google.com/store/apps/details?id=com.horizoncandidateapp" target="_blank" rel="noopener">
      <img src="../../../assets/img/icon/Frame 2.png" alt="icons8-linkedin_filled" />
      <div class="social-hover">Play Store</div>
    </a>
    <br>
    <a href="https://appgallery.huawei.com/app/C103989545" target="_blank" rel="noopener"><img
        src="../../../assets/img/icon/Frame 3.png" alt="icons8-youtube_filled" />
      <div class="social-hover">AppGallery</div>
    </a>
    <br>



  </div>
  <div class="container-fluid">
    <nav class="MainNav"> -->
<!-- old comment <div class="contact">
        <div class="contact_way">
          <a href="tel:600544737">
            <span class="cw_label">
              {{ "header.tollfree" | translate }}: &nbsp;
            </span>
            <span class="cw_number"> 6005 44737 (HIRES)</span></a
          >

          <a href="tel:+971600544737">
            <span class="cw_label">
              {{ "header.reachus" | translate }}: &nbsp;
            </span>
            <span class="cw_number">+971 6005 44737</span></a
          > 
        </div>
        <div class="social_link">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/HiresGulf/?modal=admin_todo_tour"
                target="_blank"
                rel="noopener"
                ><img
                  src="../assets/img/icon/icons8-facebook_new.png"
                  alt="facebook"
              /></a>
            </li>
            <li>
              <a
                href="https://twitter.com/HiresGulf"
                target="_blank"
                rel="noopener"
                ><img src="../assets/img/icon/icons8-twitter.png" alt="twitter"
              /></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/hiresgulf/?viewAsMember=true"
                target="_blank"
                rel="noopener"
                ><img
                  src="../assets/img/icon/icons8-linkedin_filled.png"
                  alt="linkedin"
              /></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/hiresgulf/"
                target="_blank"
                rel="noopener"
                ><img
                  src="../assets/img/icon/icons8-instagram_new.png"
                  alt="instagram"
              /></a>
            </li>
          </ul>
        </div>
      </div> -->

<!-- <div>
        <div class="brand">
          <a [routerLink]="routerLinkHome" rel="canonical">
            <img src="../assets/img/logo.png" alt="hiresgulf-logo" />
          </a>
        </div>
      </div> -->

<!-- old comment <div class="View_er" style="background-color: aqua; display: none;">
        <app-language-change></app-language-change>
        <ul class="loginFor" *ngIf="
            !authenticationService.isAuthenticated() && !empAuth.isLoggedIn()
          ">
          <li class="login_forwrd">
            <span>{{ "header.loginDropDownTitleLogin" | translate }}</span>
            <ul>
              <li>
                <a [routerLink]="['/candidate', 'login']" routerLinkActive="router-link-active">{{
                  "header.loginDropDownCandidate" | translate }}
                </a>
              </li>
              <li>
                <a [routerLink]="['/employer', 'login']">{{
                  "header.loginDropDownEmployer" | translate
                  }}</a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="loginFor" *ngIf="authenticationService.isAuthenticated()">
          <li class="login_forwrd">
            <span> {{ userName | slice: 0:10 }} {{ " " }} </span>
            <ul>
              <li>
                <a [routerLink]="['/candidate', 'dashboard']" routerLinkActive="router-link-active">
                  Dashboard
                </a>
              </li>
              <li>
                <a (click)="logoutAndRefresh()">
                  {{ "header.loginDropDownTitleLogout" | translate }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="loginFor" *ngIf="empAuth.isLoggedIn()">
          <li class="login_forwrd">
            <span> {{ empUserName | slice: 0:10 }} {{ " " }} </span>
            <ul>
              <li>
                <a [routerLink]="['/employers', 'dashboard']" routerLinkActive="router-link-active">
                  Dashboard
                </a>
              </li>
              <li>
                <a (click)="logoutAndRefreshEmp()">
                  {{ "header.loginDropDownTitleLogout" | translate }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <button class="BergerMenu" (click)="navbarToggle = !navbarToggle">
          <img src="../../assets/img/icon/menu.svg" alt="hiresgulf-menu" />
        </button>
      </div> -->

<!-- <div>
        <div class="View_er post-login">
          <ul class="loginFor login-lg" *ngIf="
              !authenticationService.isAuthenticated() && !empAuth.isLoggedIn()
            ">
            <li class="login_forwrd">
              <span>{{ "header.loginDropDownTitleLogin" | translate }}</span>
              <ul>
                <li>
                  <a [routerLink]="['/candidate', 'login']" routerLinkActive="router-link-active">{{
                    "header.loginDropDownCandidate" | translate }}
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/employer', 'login']">{{
                    "header.loginDropDownEmployer" | translate
                    }}</a>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="loginFor" *ngIf="authenticationService.isAuthenticated()">
            <li class="login_forwrd">
              <span> {{ userName | slice: 0:10 }} {{ " " }} </span>
              <ul>
                <li>
                  <a [routerLink]="['/candidate', 'dashboard']" routerLinkActive="router-link-active">
                    Dashboard
                  </a>
                </li>
                <li>
                  <a (click)="logoutAndRefresh()">
                    {{ "header.loginDropDownTitleLogout" | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="loginFor" *ngIf="empAuth.isLoggedIn()">
            <li class="login_forwrd">
              <span> {{ empUserName | slice: 0:10 }} {{ " " }} </span>
              <ul>
                <li>
                  <a [routerLink]="['/employers', 'dashboard']" routerLinkActive="router-link-active">
                    Dashboard
                  </a>
                </li>
                <li>
                  <a (click)="logoutAndRefreshEmp()">
                    {{ "header.loginDropDownTitleLogout" | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <button class="BergerMenu" (click)="navbarToggle = !navbarToggle">
            <img src="../../assets/img/icon/menu.svg" alt="hiresgulf-menu" />
          </button>
        </div>

        <div class="login-btn" *ngIf="
            !authenticationService.isAuthenticated() && !empAuth.isLoggedIn()
          ">
          <span [routerLink]="['/candidate', 'login']">
            <button class="ca-btn">
              {{ "header.loginDropDownCandidate" | translate }}
            </button>
            <button class="icon-btn-ca">
              <img src="/assets/img/icon/Signin_Icon.png" alt="" class="icon" />
            </button>
          </span>

          <span [routerLink]="['/employer', 'login']">
            <button class="em-btn">
              {{ "header.loginDropDownEmployer" | translate }}
            </button>
            <button class="icon-btn-em">
              <img src="/assets/img/icon/Recruiter_Icon.png" alt="" class="icon" />
            </button>
          </span>

          <span [routerLink]="[
              getViewMode() === 'candidate'
                ? '/candidate/register'
                : '/employer/signup'
            ]">
            <button class="signup-btn">
              {{ getCurrentLanguage() === "en" ? "Sign Up" : "سجل" }}
            </button>
            <button class="icon-btn-signup">
              <img src="/assets/img/icon/Signin_Icon.png" alt="" class="icon" />
            </button>
          </span>

          <div class="transaltor">
            <app-language-change [homeFlag]="true"></app-language-change>
          </div>
        </div>

        <br />
        <div class="navbar navbar-links" [ngClass]="{ active: navbarToggle }">
          <ul class="">
            <ng-container *ngFor="let item of navItems; index as i">
              <li class="item" *ngIf="hideNavItem(item.url)" [ngClass]="{ active: checkActiveUrl(item.url) }">
                <a style="cursor: pointer" class="nav-link" (click)="goToRoute(item.url)" tabindex="0"
                  *ngIf="!['EMPLOYERS', 'For Candidates','للمرشحين', 'لأصحاب العمل'].includes(item.name)">
                  {{ item.name }} -->
<!-- old comment {{router.url.split(item.url).length==2}} -->
<!-- </a>
                <a class="nav-link" (click)="goToRoute(item.url)" tabindex="0"
                  *ngIf="['EMPLOYERS', 'For Candidates','للمرشحين', 'لأصحاب العمل'].includes(item.name)"
                  style="padding-top: 13px; padding-bottom: 0px">
                  <p style="
                      cursor: pointer;
                      background-color: #f36f21;
                      padding: 10px;
                      color: white;
                      border-radius: 5px;
                    ">
                    {{ item.name }}
                  </p>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </nav> -->

<!-- <div class="navbar navbar-links-lg" [ngClass]="{ active: navbarToggle }">
      <ul class="">
        <ng-container *ngFor="let item of navItems; index as i">
          <li class="item" *ngIf="hideNavItem(item.url)" [ngClass]="{ active: checkActiveUrl(item.url) }">
            <a style="cursor: pointer" class="nav-link" (click)="goToRoute(item.url)" tabindex="0">
              {{ item.name }}
            </a> -->
<!-- old comment <a style="
                cursor: pointer;
                background-color: #f36f21;
                padding-left: 10px;
                padding-right: 10px;
                color: white;
              " class="nav-link" (click)="goToRoute(item.url)" tabindex="0"
              *ngIf="['EMPLOYERS', 'For Candidates'].includes(item.name)">
              {{ item.name }}
            </a> -->
<!-- </li>
        </ng-container>
      </ul>
      <app-language-change></app-language-change>
    </div>
  </div>
</header> -->
