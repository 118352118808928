<!-- Modal Code -->
<div class="popup">
  <div class="Shortlist_to">
    <form class="pe-s-form">
      <div class="headView">
        <span class="name">{{ title }} </span>
        <span class="close" (click)="closePopup()"></span>
      </div>
      <div class="field_Shortlist">
        <span class="ember-view">
          <input type="text" [value]="jobLink" #jobInputBox  />
        </span>
        <button
          class="forword"
          type="button"
          (click)="copyJobLink(jobInputBox)"
        >
        Copy
        </button>
      </div>
    </form>
  </div>
</div>
