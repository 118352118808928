import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { CandidateStageGuard } from "./common/auth/candidate-stage.guard";


// const RouteMatcher =
//     (url: UrlSegment[]) =>
//      {
//        console.log("url with RouteMatcher",url);
//        console.log("[AFTER SLICE] url with RouteMatcher",url.slice(0, -1));
//        return {
//         consumed: url.slice(0, -1)
//       }};
const routes: Routes = [
  {
    path: "candidate",
    children: [
      {
        path: "login",
        loadChildren: () =>
          import(
            "./common-modules/candidate-auth/candidate-login/candidate-login.module"
          ).then((m) => m.CandidateLoginModule),
      },
      {
        path: "register",
        loadChildren: () =>
          import(
            "./common-modules/candidate-auth/candidate-signup/candidate-signup.module"
          ).then((m) => m.CandidateSignupModule),
      },
      {
        path: "resetPassword",
        canActivate: [CandidateStageGuard],
        loadChildren: () =>
          import(
            "./common-modules/candidate-auth/candidate-reset-password/candidate-reset-password.module"
          ).then((m) => m.CandidateResetPasswordModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./candidate/candidate.module").then((m) => m.CandidateModule),
      },
    ],
  },
  {
    path: "employer/login",
    canActivate: [CandidateStageGuard],
    loadChildren: () =>
      import(
        "./common-modules/employer-auth/employer-login/employer-login.module"
      ).then((m) => m.EmployerLoginModule),
  },
  {
    path: "employer/signup",
    canActivate: [CandidateStageGuard],
    loadChildren: () =>
      import(
        "./common-modules/employer-auth/employer-signup/employer-signup.module"
      ).then((m) => m.EmployerSignupModule),
  },
  {
    path: "employer/resetPassword",
    canActivate: [CandidateStageGuard],
    loadChildren: () =>
      import(
        "./common-modules/employer-auth/employer-reset-password/employer-reset-password.module"
      ).then((m) => m.EmployerResetPasswordModule),
  },
  {
    path: "employers",
    canActivate: [CandidateStageGuard],
    loadChildren: () =>
      import("./employer/employer.module").then((m) => m.EmployerModule),
  },

  {
    path: "",
    // canActivate: [CandidateStageGuard],
    loadChildren: () =>
      import("./marketing/marketing.module").then((m) => m.MarketingModule),
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
