<div class="popup">
  <div class="Shortlist_to">
    <form class="pe-s-form" [formGroup]="form" (ngSubmit)="formSubmit()">
      <div class="headView">
        <span
          class="name"
          style="width: 100%; text-align: center; margin-right: 10px"
          >{{ name }} wants to know</span
        >
        <span class="close" (click)="closePopup()"></span>
      </div>
      <div class="field_Shortlist">
        <span class="ember-view">
          <h2>{{ questions[count].question }}</h2>
          <div>
            <mat-slide-toggle formControlName="ans" style="margin: 20px 0">{{
              ans.value ? "Yes" : "No"
            }}</mat-slide-toggle>
          </div>
        </span>
        <div style="height: 41px; width: 1px; float: right">
          <button class="forword" *ngIf="ans.valid" type="submit">Next</button>
        </div>
      </div>
    </form>
  </div>
</div>
