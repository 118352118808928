import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { EmployerLogin } from "../../../models/response/EmployerLogin";
import Employer from "../../../models/employer/Employer";
import * as routes from "../../../routes";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class EmployerAuthenticationService {
  private currentUserSubject: BehaviorSubject<Employer>;
  public currentUser: Observable<Employer>;

  private LOCAL_STORAGE_KEY = "employer_auth";

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Employer>(
      JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Employer {
    return this.currentUserSubject.value;
  }

  public isLoggedIn(): boolean {
    // return !!this.currentUserValue;
    return localStorage.getItem(this.LOCAL_STORAGE_KEY) ? true : false;
  }

  getEmployerAuth() {
    let value = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
    return value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(routes.get("recruiter.login"), { email, password })
      .pipe(
        map((resp: EmployerLogin) => {
          const user = (resp.user_info as unknown) as Employer;
          user.token = resp.token;
          localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(user));
          localStorage.setItem("viewMode", "employer");
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
    // this.currentUserSubject.onNext(EMPTY);
    setTimeout(() => {
      // this.currentUserSubject.next(null);
    }, 100);
    // let removeToken = localStorage.removeItem(this.LOCAL_STORAGE_KEY);
    // if (removeToken == null) {
    //   this.router.navigate(["employer/login"]);
    // }
  }
  forgotPassword(payload: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "",
      }),
    };
    return this.http.post(
      `${environment.baseUrl}/recruiter/auth/forgotPassword`,
      payload,
      httpOptions
    );
  }
}
