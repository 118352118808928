import { Injectable } from '@angular/core';
import BaseService from '../../BaseService';
import { map } from 'rxjs/operators';
import { Testimonial, TestimonialsListResponse } from '../../../models/response/home/Testimonials';
import { CompaniesListResponse, Company } from '../../../models/response/home/CompaniesListResponse';
import { PlansListResponse, Plan } from '../../../models/response/home/Plans';
import { PartnersListResponse, Partner } from '../../../models/response/home/Partners';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LandingPageService extends BaseService {

  public getTestimonials() {
    return this.httpClient.get(this.route('home.testimonials'))
      .pipe(map((resp: TestimonialsListResponse): Testimonial[] => resp.data));
  }

  public getCompanies(start?: string, limit?: string, industry?: string, location?: string) {
    return this.httpClient.get(this.route('home.companies'))
      .pipe(map((resp: CompaniesListResponse): Company[] => resp.data));
  }

  public getPlans() {
    return this.httpClient.get(this.route('recruiter.plans'))
      .pipe(map((resp: PlansListResponse): Plan[] => resp.data));
  }

  public getPartners(start?: string, limit?: string) {
    return this.httpClient.get(this.route('home.partners') + `?start=${start}&limit=${limit}`)
      .pipe(map((resp: PartnersListResponse): Partner[] => resp.data));
  }

  public getQuicklinksList() {
    return this.httpClient.get(this.route('home.getQuickLinks'))
    // .pipe(map((resp: PlansListResponse): Plan[] => resp.data));
  }

  public getNonServicePageCms(slug) {
    return this.httpClient.get(this.route('home.nonServicePage') + `/${slug}`)
  }

  public getServicePageCms(slug) {
    return this.httpClient.get(this.route('home.servicePage') + `/${slug}`)
  }

  public getExperienceList() {
    return this.httpClient.get(this.route("home.exp_drop_down"));
  }

  //for user count
  public logInUser() {
    return this.httpClient.get(`${environment.baseUrl}/vjob/logInUserCount`).subscribe()
  }
  public logOutInUser() {
    return this.httpClient.get(`${environment.baseUrl}/vjob/logOutUserCount`).subscribe()
  }
  public visitorIn() {
    return this.httpClient.get(`${environment.baseUrl}/vjob/visitorInCount`).subscribe();
  }
  public visitorOut() {
    return this.httpClient.get(`${environment.baseUrl}/vjob/visitorOutCount`).subscribe()
  }

}
