import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CandidateAuthenticationService } from "../auth/candidate-authentication.service";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  httpOptions: any;
  messageData = new BehaviorSubject(null);
  messageDataObs$ = this.messageData.asObservable();

  constructor(
    private http: HttpClient,
    private auth: CandidateAuthenticationService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
  }
  getMessageList(): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}/candidate/message`, this.httpOptions)
      .pipe(
        map((res) => {
          this.messageData.next(res);
          return res;
        })
      );
  }
  getUserMessages(id): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/message/getUserMessages?id=${id}`,
      this.httpOptions
    );
  }
  sendMessage(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/message/sentMessage`,
      payload,
      this.httpOptions
    );
  }

  removeMessage(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/message/removeMessage`,
      payload,
      this.httpOptions
    );
  }
}
