import { Component, OnInit } from "@angular/core";
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: "app-c-app-banner",
  templateUrl: "./c-app-banner.component.html",
  styleUrls: ["./c-app-banner.component.scss"],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
      })),
      transition('void <=> *', [
        animate(500)
      ]),
    ])
  ]
})
export class CAppBannerComponent implements OnInit {
  
  isVisible = true;

  constructor() {  }

  ngOnInit(): void {
    const bannerState = sessionStorage.getItem('BannerState');
    if (bannerState === 'true') {
      console.log('closed');
      this.isVisible = false;
    }
  }

  closeBanner() {
    this.isVisible = false;
    sessionStorage.setItem('BannerState', 'true');
  }
}
