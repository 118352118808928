import { Component, OnInit } from "@angular/core";
import { CandidateApisService } from "src/app/services/candidate/apis/candidate-apis.service";
import { EmployerAuthenticationService } from "src/app/services/employer/auth/employer-authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationEnd, Router } from "@angular/router";
import { LandingPageService } from "src/app/services/marketing/landing/landing-page.service";

@Component({
  selector: "app-c-home-footer",
  templateUrl: "./c-home-footer.component.html",
  styleUrls: ["./c-home-footer.component.scss"],
})
export class CHomeFooterComponent implements OnInit {
  // industries: any = [
  //   "Oil and Gas jobs",
  //   " Construction Jobs",
  //   "Banking and Financial Sector Jobs",
  //   "IT and Telecom",
  //   "Clinics and Medical Centers",
  //   "Hospitals and Pharma",
  //   "Real Estate",
  //   "Facilities Management",
  //   "Retail and FMCG",
  //   "Trading"
  // ]
  path: string = window.location.pathname;
  addStyle: boolean = false;
  industries: any = [];
  matMenuClicked: boolean = false
  quicklinkList: any = []
  cmsData: any = {
    banner_image_url: "",
  };


  constructor(
    private api: LandingPageService,
    public empAuth: EmployerAuthenticationService,
    private translate: TranslateService,
    private router: Router,
    private landingApi: LandingPageService

  ) { }



  ngOnInit(): void {
    // this.getIndustries()
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        if (this.path.includes("/post")) {
          this.addStyle = true;
        } else {
          this.addStyle = false;
        }
      }
    });
    this.getTranslatedIndustries();
    this.translate.onLangChange.subscribe((res) => {
      this.getTranslatedIndustries();
    });
    this.getQuicklinksList()
    this.fetchSeo("footer-image");

  }
  getTranslatedIndustries() {
    this.translate
      .get("footer.bottom.jobsByIndustry.industries")
      .subscribe((res) => {
        this.industries = res;
      });
  }
  //  getIndustries() {
  //   this.api.getIndustries().subscribe((res:any)=>{
  // this.industries = res.data
  //   })
  // }

  btnClick() {
    this.matMenuClicked = !this.matMenuClicked
  }
  onClosed() {
    this.matMenuClicked = false
  }

  getQuicklinksList() {
    this.api.getQuicklinksList().subscribe((res: any) => {
      this.quicklinkList = res.data
    })
  }


  routeToSubScription() {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(["employer"], { queryParams: { 'view': 'subscription' } });
    })
  }

  fetchSeo(slug) {
    this.landingApi.getNonServicePageCms(slug).subscribe((res: any) => {
      this.cmsData = res.data;
      // this.seo.updateSeoData2(this.cmsData);
    });
  }
}
