<div class="dashboard" [ngClass]="{ active: mobMenuToggle }">
  <div
    class="Mburger"
    [ngClass]="{ clicked: mobMenuToggle }"
    (click)="mobMenuToggle = !mobMenuToggle"
  >
    <!-- for mobile add clicked class to Mburger -->
    <span></span>
  </div>
  <div class="short_nav">
    <ul class="side_menu">
      <li class="logo">
        <img
          class="logo_lrg"
          (click)="goToCandidateHome()"
          src="../../../assets/img/hiresgulf_logo.svg"
          alt="logo"
          style="cursor: pointer"
        />
      </li>
      <li [ngClass]="{ active: '/candidate/dashboard' == routerUrl }">
        <span class="iocn">
          <img
            class="off"
            src="../assets/img/icon/icons-8-content.png"
            alt="inactive_content_icon"
          />
          <img
            class="active"
            src="../assets/img/icon/icons-8-content-off.png"
            alt="active_icon"
          />
        </span>
        <a routerLink="/candidate/dashboard">{{
          "candidate.dashboard.leftsidebar.dashboard" | translate
        }}</a>
      </li>
      <li [ngClass]="{ active: '/candidate/myResume' == routerUrl }">
        <span class="iocn">
          <img
            class="off"
            src="../assets/img/icon/icons-8-resume.png"
            alt="inactive_resume_icon"
          />
          <img
            class="active"
            src="../assets/img/icon/icons-8-resume-off.png"
            alt="active_resume_icon"
          />
        </span>
        <a routerLink="/candidate/myResume">
          {{ "candidate.dashboard.leftsidebar.myresume" | translate }}</a
        >
      </li>
      <li [ngClass]="{ active: '/candidate/myApplications' == routerUrl }">
        <span class="iocn">
          <img
            class="off"
            src="../assets/img/icon/icons-8-paper.png"
            alt="inactive_paper_icon"
          />
          <img
            class="active"
            src="../assets/img/icon/icons-8-paper-off.png"
            alt="active_paper_icon"
          />
        </span>
        <a routerLink="/candidate/myApplications">{{
          "candidate.dashboard.leftsidebar.myapplications" | translate
        }}</a>
      </li>
      <li [ngClass]="{ active: '/candidate/savedApplications' == routerUrl }">
        <span class="iocn">
          <img
            class="off"
            src="../assets/img/icon/icons-8-star.png"
            alt="inactive_star_icon"
          />
          <img
            class="active"
            src="../assets/img/icon/icons-8-star-off.png"
            alt="active_star_icon"
          />
        </span>
        <a routerLink="/candidate/savedApplications">{{
          "candidate.dashboard.leftsidebar.savedapplications" | translate
        }}</a>
      </li>
      <li [ngClass]="{ active: '/candidate/messenger' == routerUrl }">
        <span class="iocn">
          <img
            class="off"
            src="../assets/img/icon/icons-8-chat.png"
            alt="inactive_chat_icon"
          />
          <img
            class="active"
            src="../assets/img/icon/icons-8-chat-off.png"
            alt="active_chat_icon"
          />
        </span>
        <a routerLink="/candidate/messenger">
          {{ "candidate.dashboard.leftsidebar.messenger" | translate }}
          <ng-container *ngIf="messageUnreadCounter > 0">
            ( unread {{ messageUnreadCounter }})
          </ng-container>
        </a>
      </li>
      <li [ngClass]="{ active: '/candidate/notifications' == routerUrl }">
        <span class="iocn">
          <img
            class="off"
            src="../assets/img/icon/icons-8-doorbell.png"
            alt="inactive_doorbell_icon"
          />
          <img
            class="active"
            src="../assets/img/icon/icons-8-doorbell-off.png"
            alt="active_doorbell_icon"
          />
        </span>
        <a routerLink="/candidate/notifications">
          {{ "candidate.dashboard.leftsidebar.notification" | translate }}
          <ng-container *ngIf="notificationUnreadCounter > 0">
            ( unread {{ notificationUnreadCounter }})
          </ng-container>
        </a>
      </li>
      <li [ngClass]="{ active: '/candidate/myaccount' == routerUrl }">
        <span class="iocn">
          <img
            class="off"
            src="../assets/img/icon/icons-8-male-user.png"
            alt="inactive_male_icon"
          />
          <img
            class="active"
            src="../assets/img/icon/icons-8-male-user-off.png"
            alt="active_male_icon"
          />
        </span>
        <a routerLink="/candidate/myaccount">{{
          "candidate.dashboard.leftsidebar.myaccount" | translate
        }}</a>
      </li>
    </ul>
    <div class="language">
      <app-language-change></app-language-change>
    </div>

    <!-- <a class="log_out" href="#"> -->
    <!-- <a class="log_out" >   language</a> -->
    <a class="log_out" style="cursor: pointer" (click)="logoutAndRefresh()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
      >
        <path
          d="M1.68622 1.68622H6.74488C7.20859 1.68622 7.58799 1.30682 7.58799 0.84311C7.58799 0.379399 7.20859 0 6.74488 0H1.68622C0.758799 0 0 0.758799 0 1.68622V13.4898C0 14.4172 0.758799 15.176 1.68622 15.176H6.74488C7.20859 15.176 7.58799 14.7966 7.58799 14.3329C7.58799 13.8692 7.20859 13.4898 6.74488 13.4898H1.68622V1.68622Z"
          fill="#A1A1A1"
        />
        <path
          d="M14.8808 7.29291L12.5285 4.94063C12.4699 4.88039 12.3946 4.83902 12.3123 4.82181C12.2301 4.8046 12.1445 4.81234 12.0666 4.84403C11.9888 4.87572 11.9221 4.92992 11.8753 4.9997C11.8284 5.06948 11.8034 5.15166 11.8035 5.23572V6.74489H5.9017C5.43799 6.74489 5.05859 7.12429 5.05859 7.588C5.05859 8.05171 5.43799 8.43111 5.9017 8.43111H11.8035V9.94028C11.8035 10.3197 12.2588 10.5052 12.5201 10.2354L14.8724 7.88309C15.041 7.7229 15.041 7.4531 14.8808 7.29291Z"
          fill="#A1A1A1"
        />
      </svg>
      &nbsp; {{ "candidate.dashboard.leftsidebar.logout" | translate }}</a
    >
  </div>
</div>
