import construct = Reflect.construct;
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as routes from "../routes";
import { Injectable } from "@angular/core";
import { EmployerAuthenticationService } from "./employer/auth/employer-authentication.service";

@Injectable()
export default class BaseService {
  header: any;
  constructor(
    protected httpClient: HttpClient,
    protected service: EmployerAuthenticationService
  ) {
    this.service.currentUser.subscribe((res) => {
      this.header = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: res?.token?res.token:"",
        }),
      };
    });
  }

  protected route(path: string) {
    return routes.get(path);
  }

  protected toFormData(formObj: any, formData: FormData) {
    Object.keys(formObj).forEach((n) => {
      const obj = formObj[n];
      if (obj instanceof Array) {
        obj.forEach((o) => {
          const fileObj = {};
          fileObj[n] = o;
          this.toFormData(fileObj, formData);
        });
      } else if (obj instanceof File) {
        formData.append(n, obj, obj.name);
      } else {
        formData.append(n, obj);
      }
    });

    return formData;
  }
}
