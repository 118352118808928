<h1 mat-dialog-title>{{ data.heading }}</h1>
<div mat-dialog-content>
  <p>{{ data.content }}</p>
  <!-- <mat-form-field>
    <mat-label>Favorite Animal</mat-label>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field> -->
</div>
<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button> -->
  <button mat-button [mat-dialog-close]="'Ok btn clicked'" cdkFocusInitial>
    Ok
  </button>
</div>
