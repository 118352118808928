import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as routes from "../../../routes";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CandidateApisService {
  baseUrlOfApp: string = document.location.origin;
  constructor(private router: Router, private http: HttpClient) { }
  registerCandidate(payload: any) {
    return this.http.post(routes.get("candidate.register"), payload);
  }
  getDashboard() {
    return this.http.get(routes.get("candidate.dashboard"));
  }
  getHomeData() {
    return this.http.get(routes.get("home.dashboard"));
  }

  // getFilterJobTitles: "getFilterJobTitles",
  //     getLocation: "getLocation",
  // job search apis

  // protected route(path: string) {
  //   return routes.get(path);
  // }

  // getJobTitles(search?: string) {
  //   return this.http
  //     .get(this.route("home.getFilterJobTitles"), {
  //       params: search ? { search } : {},
  //     });
  // }



  getJobTitles(query: any) {
    let route = routes.get("home.getFilterJobTitles");
    let params = new HttpParams();
    params = params.set('param', query)
    if (query) {
      route = route + `?search=${params}`;
    }
    return this.http.get(route);
  }
  getJobLocation(query: any) {
    let route = routes.get("home.getLocation");
    if (query) {
      route = route + `?location=${query}`;
    }
    return this.http.get(route);
  }

  getJobAreas(query: any) {
    let route = routes.get("home.getCountryAndCities");
    if (query) {
      route = route + `?search=${query}`;
    }
    return this.http.get(route);
  }

  getJobsWithFilters(queryParams: any, limit: any) {
    //  delete queryParams['salary']
    // delete queryParams['experience']
    
    const page = queryParams['page']
    delete queryParams['page']
    let filtersApi = `?limit=${limit}&start=${page ? (page - 1) * 10 : 0}` + this.parseFilterToQuery(queryParams);

    let filtersApi2 = decodeURI(filtersApi)
    let route = routes.get("home.getJobs") + filtersApi2;

    return this.http.get(route);
  }

  getJobFilter(filterApi: any) {
    filterApi = routes.get(`home.${filterApi}`);
    return this.http.get(filterApi);
  }
  getFilters() {
    return this.http.get(routes.get(`home.getFilterOptions`));
  }
  // end of job search apis

  // get single job detail

  // get job details outside dashboard
  getJobDetails(jobId: any) {
    return this.http.get(routes.get(`home.getJobDetails`) + `?job_id=${jobId}`);
  }
  //end of get job details outside dashboard

  // get job details inside dashboard
  getJobDetailsInsideDash(jobId: any) {
    return this.http.get(
      routes.get(`candidate.myjob_getJobDetails`) + `?job_id=${jobId}`
    );
  }
  //end of get job details inside dashboard

  cancelApplication(payload: {}): Observable<any> {
    return this.http.post(
      routes.get(`candidate.jobs_candidate_cancelApplication`),
      payload
    );
  }

  // end of single job detail

  //  save job
  saveJob(payload: any) {
    return this.http.post(routes.get(`candidate.save_job`), payload);
  }

  // end of save job
  getPartners(start?: number, limit?: number) {
    return this.http.get(
      routes.get("home.partners") + `?start=${start}&limit=${limit}`
    );
  }
  applyJob(payload: any) {
    //  job_id
    return this.http.post(routes.get(`candidate.applyJob`), payload);
  }
  // get companies data or explore companies

  getCompaniesWithFilters(queryParams: any, start: any, limit: any) {
    let filtersApi =
      `?start=${start}&limit=${limit}` + this.parseFilterToQuery(queryParams);
    let route = routes.get(`home.companies`) + filtersApi;
    return this.http.get(route);
  }
  // end of get companies data or explore companies

  getIndustries() {
    return this.http.get(routes.get(`home.getIndustries`));
  }
  getCountryIndustryList() {
    return this.http.get(routes.get(`home.getCountryIndustryList`));
  }

  getCompaniesJobs(company_id: any, start: any, limit: any) {
    return this.http.get(
      routes.get(`home.getCompaniesJobs`) +
      `?company_id=${company_id}&start=${start}&limit=${limit}`
    );
  }

  contactUs(payload: any) {
    return this.http.post(routes.get(`home.contactUs`), payload);
  }

  // workforce apis

  getCountries() {
    return this.http.get(routes.get(`workforce.clientOpList`));
  }
  getCitiesOfCountry(countryId: number) {
    return this.http.get(routes.get(`workforce.city`) + `/${countryId}`);
  }

  registerClient(payload: any) {
    return this.http.post(routes.get(`workforce.clientRegistration`), payload);
  }
  getSupplierOpList() {
    return this.http.get(routes.get(`workforce.supplierOpList`));
  }
  registerSupplier(payload: any) {
    return this.http.post(
      routes.get(`workforce.supplierRegistration`),
      payload
    );
  }
  getenquiryOpList() {
    return this.http.get(routes.get(`workforce.enquiryOpList`));
  }
  registerEnquiry(payload: any) {
    return this.http.post(routes.get(`workforce.enquiryRegistration`), payload);
  }

  // end of workforce apis
  // parse filter array to query string
  parseFilterToQuery(queryParams: Array<any>) {
    let obj = {};
    let filters = [];
    let key = "";
    let filtersApi = "";
    let filterKeys = Object.keys(
      Object.entries(queryParams).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      )
    );
    filterKeys.forEach((fKey) => {
      obj[fKey] = queryParams[fKey];
      filters.push(obj);
      obj = {};
    });

    filters.forEach((filter, i) => {
      key = Object.keys(filter)[0];
      filtersApi = filtersApi + `&${String(key)}=${encodeURIComponent(filter[key])}`; //added encodeURIComponent to change & to %26
    });
    return decodeURI(filtersApi);
  }
  //end of  parse filter array to query string

  // scroll to top
  scrollTop() {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    // let scrollToTop = window.setInterval(() => {
    //   let pos = window.pageYOffset;
    //   if (pos > 0) {
    //     window.scrollTo(0, pos - 20); // how far to scroll on each step
    //   } else {
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 5);
  }
  // end of scroll to top
  getFilterJobLocations(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/vjob/getFilterOptions`);
  }

  getActiveIndustries(): Observable<any> {
    return this.http.get(routes.get("home.getActiveIndustries"));
  }

  getOtpCountryList(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/recruiter/auth/getOtpCountryList`);
  }

  // start of admin apis for registering candidates by an admin
  adminAuthenticate(payload: {}): Observable<any> {
    return this.http.post(routes.get(`admin.login`), payload);
  }

  registerCandidateByAdmin(payload: any) {
    return this.http.post(routes.get("admin.candidate/register"), payload);
  }

  getAdminToken() {
    let token = localStorage.getItem("adminRedirectToken")
      ? localStorage.getItem("adminRedirectToken")
      : " ";

    return token;
  }
  // end of admin apis for registering candidates by an admin

  getEmaratiJobs(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/vjob/getEmiratiJobs?start=0&limit=10`
    );
  }

  skipResetPass(): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/skipReset`,
      {}
    );
  }
}
