import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmployerAuthenticationService} from "./employer/auth/employer-authentication.service";



@NgModule({
  declarations: [],
  providers:[EmployerAuthenticationService],
  exports: [],
  imports: [
    CommonModule
  ]
})
export class ServicesModule { }
