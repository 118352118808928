import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { RightSidebarService } from "src/app/services/candidate/right-sidebar/right-sidebar.service";
import { MyAccountService } from "src/app/services/candidate/my-account/my-account.service";
import { MessagesService } from "src/app/services/candidate/messages/messages.service";
import { MyResumeService } from "src/app/services/candidate/my-resume/my-resume.service";

@Component({
  selector: "app-c-right-sidebar",
  templateUrl: "./c-right-sidebar.component.html",
  styleUrls: ["./c-right-sidebar.component.scss"],
})
export class CRightSidebarComponent implements OnInit {
  messagesList: any[] = [];
  isUnreadMsg: boolean = false;
  isUnreadNotif: boolean = false;
  notificationsList: any = [];
  accountInfo: any = {};
  profileCompletion: string = "";
  isSkeletonLoader: boolean = true;
  isSkeletonLoader2: boolean = true;
  constructor(
    private service: RightSidebarService,
    private myAccountService: MyAccountService,
    private myResumeService: MyResumeService,
    private messageService: MessagesService
  ) {
    this.myAccountService.getUpdate().subscribe((url) => {
      this.getAccountInfo();
    });

    this.myResumeService.getPicUpdate().subscribe((isGenderChanged) => {
      if(isGenderChanged?.profilePic) return

      this.getAccountInfo();
    });
  }

  ngOnInit(): void {
    this.getMessages();
    this.getNotifications();
    this.getAccountInfo();
    this.onNotificationsUpdate$();
    this.onMessageUpdate$();
  }

  onNotificationsUpdate$() {
    this.service.notificationDataObs$.subscribe((res) => {
      if (res == true) this.isUnreadNotif = false;
    });
  }

  onMessageUpdate$() {
    this.messageService.messageDataObs$.subscribe((res) => {
      if (res == null) return;
      this.messagesList = [...res.data];
      this.messagesList.splice(5, this.messagesList.length - 6);
      this.isUnreadMsg = false;
      this.messagesList.map((res) => {
        if (res.unread_message_count >= 1) this.isUnreadMsg = true;
      });
    });
  }

  getMessages() {
    this.service.getMessages().subscribe(
      (res) => {
        this.isSkeletonLoader = false;
        this.messagesList = res.data;
        this.messagesList.map((res) => {
          if (res.unread_message_count >= 1) this.isUnreadMsg = true;
        });
      },
      (err) => {
        this.isSkeletonLoader = false;
      }
    );
  }

  getNotifications() {
    this.service.getNotifications().subscribe(
      (res) => {
        this.isSkeletonLoader2 = false;
        this.notificationsList = res.data;
        // this.notificationsList = [];
        this.notificationsList.map((res) => {
          if (res.read_status == 0) this.isUnreadNotif = true;
        });
      },
      (err) => {
        this.isSkeletonLoader2 = false;
      }
    );
  }
  getAccountInfo() {
    this.myAccountService.getAccountInfo().subscribe((res) => {
      this.accountInfo = res.data[0];
      this.profileCompletion =
        "Profile  " + this.accountInfo.profile_percentage + "%  completed";
    });
  }

  loadPlaceholder() {
    this.accountInfo.profile_pic = "../../../assets/img/logo_icon.png";
  }
}
