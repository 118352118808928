import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CandidateAuthenticationService } from "src/app/services/candidate/auth/candidate-authentication.service";
import * as routes from "../../routes";

@Injectable()
export class CandidateErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: CandidateAuthenticationService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err.url == routes.get("candidate.login") ||
          err.url.includes("/recruiter/auth/login")
        ) {
        } else if (err.url.includes("admin/login")) {
        } else if (err.status === 401) {
          this.authenticationService.doLogout();
          // location.reload();
          // this.router.navigate(["/candidate/login"]);
        } else if (err.status === 403 && err.url.includes("/recruiter")) {
          if (err.url.includes("/recruiter"))
            this.router.navigate(["/employers/applications"]);
          // return;
        }
        const error = err.error.message || err.statusText;
        // console.log(err);

        this._snackBar.open(error, `X`, {
          duration: 2000,
        });
        return throwError(error);
      })
    );
  }
}
