<div class="Profile_view_right">
  <div class="profile_burger">
    <!-- for mobile add active class to profile_burger -->
  </div>

  <div class="short_Profile_view active">
    <div class="Profile_view">
      <div class="Profile_img">
        <div class="Profile_pic">
          <img
            [src]="accountInfo.profile_pic"
            [style.opacity]="
              accountInfo?.profile_pic?.includes('assets/img/logo_icon.png')
                ? 0.5
                : 1
            "
            (error)="loadPlaceholder()"
            style="border-radius: 5px; border: 0;"
            alt="profile_pic"
          />
        </div>
      </div>
      <span class="Profile_status completed">{{ profileCompletion }} </span>
      <span class="Profile_name">{{ accountInfo.name }}</span>
      <span class="Profile_designation">{{ accountInfo.designation }}</span>
    </div>

    <div class="info_view">
      <!-- Removed Messages from rightside bar -->
      <!-- <div class="chat_View">
        <div class="title">
          <span class="name">{{
            "employer.dashboard.rightsidebar.messages" | translate
          }}</span>
          <span class="icon" [ngClass]="isUnreadMsg ? 'active' : ''">
            <img
              class="off"
              src="../assets/img/icon/icons-8-chat.png"
              alt="inactive_chat_icon"
            />
          </span>
        </div>
        <div class="body">
          <div class="chat_block" *ngIf="isSkeletonLoader">
            <div class="photo">
              <ngx-skeleton-loader
                animation="progress"
                appearance="circle"
                [theme]="{
                  width: '29.75px',
                  height: '39.7px',
                  margin: '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div class="detail">
              <span>
                <ngx-skeleton-loader
                  animation="progress"
                  [theme]="{
                    width: '80px',
                    height: '13px',
                    'margin-bottom': '0px'
                  }"
                ></ngx-skeleton-loader
              ></span>
              <p>
                <ngx-skeleton-loader
                  animation="progress"
                  [theme]="{
                    width: '80px',
                    height: '7px',
                    'margin-bottom': '0px'
                  }"
                ></ngx-skeleton-loader>
              </p>
            </div>
          </div>
          <div *ngFor="let data of messagesList" class="chat_block">
            <div class="photo">
              <img [src]="data.user_info.profile_pic" alt="user_photo" />
            </div>
            <div class="detail">
              <span>{{ data.user_info.name }}</span>
              <p>
                {{ data.unread_message_count }}
                {{
                  "employer.dashboard.rightsidebar.unreadmessages" | translate
                }}
              </p>
            </div>
          </div>
          <div class="messagesListings" *ngIf="!isSkeletonLoader">
            <span class="end_list" *ngIf="messagesList.length === 0">{{
              "employer.dashboard.rightsidebar.nomessages" | translate
            }}</span>
          </div>
        </div>
      </div> -->

      <div class="Notifications">
        <div class="title">
          <span class="name">{{
            "employer.dashboard.rightsidebar.notifications" | translate
          }}</span>
          <span class="icon" [ngClass]="isUnreadNotif ? 'active' : ''">
            <img
              class="off"
              src="../assets/img/icon/icons-8-doorbell.png"
              alt="inactive_doorbell_icon"
            />
          </span>
        </div>
        <div class="body">
          <div class="Notifi_block" *ngIf="isSkeletonLoader2">
            <div class="icon">
              <ngx-skeleton-loader
                animation="progress"
                appearance="circle"
                [theme]="{
                  width: '32px',
                  height: '32px',
                  margin: '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <a class="detail">
              <ngx-skeleton-loader
                animation="progress"
                [theme]="{
                  width: '116px',
                  height: '39px',
                  'margin-bottom': '0px'
                }"
              ></ngx-skeleton-loader>
            </a>
          </div>
          <div *ngFor="let data of notificationsList" class="Notifi_block">
            <div class="icon aletr"></div>
            <!-- add these classes ('reject','new') in place of 'aletr'  to change icon style -->
            <a class="detail">
              <span>{{ data.message }}</span>
              <p>{{ data.notification_date }}</p>
            </a>
          </div>
          <div class="notificationListings" *ngIf="!isSkeletonLoader2">
            <span class="end_list" *ngIf="notificationsList.length === 0">{{
              "employer.dashboard.rightsidebar.nonotifications" | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
