import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmployerAuthenticationService } from "../../services/employer/auth/employer-authentication.service";
import { CandidateAuthenticationService } from "src/app/services/candidate/auth/candidate-authentication.service";
import { LanguageService } from "src/app/services/language.service";
import { environment } from "src/environments/environment";
import { CandidateApisService } from "src/app/services/candidate/apis/candidate-apis.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: EmployerAuthenticationService,
    private CandidateAuthenticationService: CandidateAuthenticationService,
    private adminApis: CandidateApisService,
    private langService: LanguageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if(request.url.includes('candidate') || request.url.includes('vjob') && this.CandidateAuthenticationService.isAuthenticated()) {
    if (
      request.url.includes("candidate") ||
      (request.url.includes("vjob") &&
        this.CandidateAuthenticationService.isAuthenticated())
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.CandidateAuthenticationService.getToken()}`,
        },
      });
    }
    if (request.url.includes("recruiter")) {
      const currentUser = this.authenticationService.currentUserValue;
      if (currentUser && currentUser.token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        });
      }
    }

    // to attach admin redirect token
    if (request.url.includes("admin/candidate/register")) {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.adminApis.getAdminToken()}`,
        },
      });
    }

    // For arabic api data start
    if (
      request.url.includes(`${environment.baseUrl}/vjob`) ||
      request.url.includes(`${environment.baseUrl}/candidate`)
    ) {
      if (!request.method.includes("GET")) return next.handle(request);
      if (
        request.url.includes(`getBlogsMain`) ||
        request.url.includes(`getSingleBlog`)
      )
        return next.handle(request);

      if (this.langService.language === "arb") {
        request = request.clone({
          setParams: {
            lang: "ar",
          },
        });
      }
    }

    // For arabic api data end

    return next.handle(request);
  }
}
