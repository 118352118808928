import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageChangeComponent } from './language-change.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CandidateApisService } from '../../services/candidate/apis/candidate-apis.service';
import { HttpLoaderFactory } from '../../app.module';

@NgModule({
  declarations: [
    LanguageChangeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    },
    isolate: false
}),
  ],
  exports:[
    LanguageChangeComponent,
    TranslateModule
  ]
})

export class LanguageChangeModule {
  static forRoot(): ModuleWithProviders<LanguageChangeModule> {
    return {
      ngModule: LanguageChangeModule,
      providers: [CandidateApisService]
    }
  }
 }
