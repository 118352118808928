// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: "http://3.16.29.111:5051",
  baseUrl: "https://apidev.hiresgulf.com",  //local
  // baseUrl: "http://localhost:5050",  //local

  // baseUrl: "https://api.hiresgulf.com",
  ccId: "6038e87204ecf066f76979ce", //testing id
  // ccId: "6037649fe9cf8d150b3ad11c", // test testing id
  googleRedirectUri: "http://localhost:4200/"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
