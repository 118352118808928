<div class="popup" *ngIf="isReset == 0 && showPopup == true">
  <div class="Change_password">
    <div class="head" style="border-bottom: none">
      <!-- <span class="title">
        {{
        "candidate.myaccount.jobdetailsbox.changeemailpopup.title" | translate
      }}
      </span> -->
      <span class="close" (click)="closePopup()"></span>
    </div>
    <div class="body">
      <form>
        <div class="action-box" style="padding: unset; justify-content: center">
          <span style="font-size: 20px">{{
            "candidate.dashboard.resetpass.text" | translate
          }}</span>
        </div>
        <div
          class="action-box"
          style="padding: unset; justify-content: space-around"
        >
          <button class="abote" type="button" (click)="onClickSkip()">
            {{ "candidate.dashboard.resetpass.buttons.skip" | translate }}
          </button>
          <button class="search" type="button" (click)="onClickOk()">
            {{ "candidate.dashboard.resetpass.buttons.ok" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
