<section class="app-download-container" *ngIf="isVisible" @fadeInOut>
  <div class="app-download-wrapper">
    <div class="void_space"></div>
    <div class="mid_content">
      <p class="app-download-text">
        Download HiresGulf App on
        <span class="app-download-highlight">Android & iOS</span>
      </p>

      <div class="ml_4">
        <a
          href="https://play.google.com/store/apps/details?id=com.horizoncandidateapp&hl=en_IN"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3777e72d6096bc6fa428a45ff30f25dbadac7516cb0514e850044785e3a08c5d?placeholderIfAbsent=true&apiKey=ca8432f3bd2e41a08231e28f8ed16d85"
            alt="Download on App Store"
            class="app-store-icon"
            loading="lazy"
          />
        </a>
      </div>

      <div class="ml_2">
        <a
          href="https://apps.apple.com/in/app/hiresgulf/id1532042887"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7046521e57b749983b83a45d563f1d1f4f0e5bf5502bfa5ee30ff5e54099fa15?placeholderIfAbsent=true&apiKey=ca8432f3bd2e41a08231e28f8ed16d85"
            alt="Get it on Google Play"
            class="app-store-icon"
            loading="lazy"
          />
        </a>
      </div>
    </div>
    <button class="close-banner" (click)="closeBanner()">
      <img src="../../../assets/img/close_btn.svg" alt="" />
    </button>
  </div>
</section>
