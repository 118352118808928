import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import * as routes from "../../../routes";
import { APP_URLS } from "../../../routes";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class CandidateAuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  currentUser: Observable<any>;
  header: any;

  constructor(private router: Router, private http: HttpClient) {
    let Authorization = this.getToken();
    // this.currentUserSubject = new BehaviorSubject<any>(
      // (localStorage.getItem("viewMode")));
    // this.currentUserSubject = new BehaviorSubject<any>(null);
    // this.currentUser = this.currentUserSubject.asObservable();
    this.header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization,
      }),
    };
  }

  // public get currentUserValue() {
  //   return this.currentUserSubject.value;
  // }

  getActiveRoute() {
    return this.router.url;
  }

  isAuthenticated() {
    return localStorage.getItem("Ctoken") ? true : false;
  }

  isRegistering(){
    const res = localStorage.getItem("CRegister") === '1' ? true : false;   // 1 = true
    return res;
  }

  login(user: any) {
    return this.http.post(routes.get("candidate.login"), user).subscribe(
      (res: any) => {
        this.candidateLoginProcess(res);
      },
      (err: any) => { }
    );
  }

  candidateLoginProcess(res) {
    localStorage.setItem("Ctoken", res.token.trim());
    localStorage.setItem("CuserId", res.user_info.id);
    localStorage.setItem("Cemail", res.user_info.email);
    localStorage.setItem("cuser", res.user_info.name);
    localStorage.setItem("profile_pic", res.user_info.profile_pic);
    localStorage.setItem("viewMode", "candidate");
    // this.currentUserSubject.next(res.user_info);

    if (res.user_info.reg_stage == 1) {
      this.router.navigate(["candidate/" + APP_URLS.candidate.register + "/1"]);
    } else if (res.user_info.reg_stage == 2) {
      this.router.navigate(["candidate/" + APP_URLS.candidate.register + "/2"]);
    } else if (res.user_info.reg_stage == 3 || res.user_info.reg_stage == 4) {
      this.router.navigate(["candidate/" + APP_URLS.candidate.register + "/3"]);
    } else if (
      res.user_info.reg_stage == 5 ||
      res.user_info.reg_stage == 6 ||
      res.user_info.reg_stage == 7
    ) {
      this.router.navigate(["candidate/" + APP_URLS.candidate.register + "/4"]);
    } else if (res.user_info.reg_stage == 8) {
      this.router.navigate(["candidate/" + APP_URLS.candidate.register + "/5"]);
    } else if (res.user_info.reg_stage == 10) {
      this.router.navigate([
        "candidate/" + APP_URLS.candidate.register + "/phone",
      ]);
    } else if (res.user_info.reg_stage == 9) {
      this.router.navigateByUrl("/candidate/" + APP_URLS.candidate.dashboard);
    }
  }

  setRegistrationStage(stage: number) {
    localStorage.setItem("CregStage", stage.toString());
  }

  getRegistrationStage(): any {
    const regStage = localStorage.getItem("CregStage");
    return regStage && regStage!= 'phone' ? parseInt(regStage, 10) : 0;
  }

  sendPasswordLink(email: any) {
    return this.http
      .post(routes.get("candidate.reset_password"), { email })
      .subscribe((res: any) => {
        this.router.navigate(["/candidate/" + APP_URLS.candidate.login]);
      });
  }

  getToken() {
    if (this.isAuthenticated()) {
      let token = localStorage.getItem("Ctoken")
        ? localStorage.getItem("Ctoken")
        : " ";
      // ;

      return token;
    }
    return "";
  }

  doLogout() {
    let removeToken = localStorage.removeItem("Ctoken");
    localStorage.removeItem("Cemail");
    localStorage.removeItem("cRegister");
    localStorage.removeItem("CregStage");    
    if (sessionStorage.getItem("skipResetPass"))
      sessionStorage.removeItem("skipResetPass");
    if (removeToken == null) {
      this.router.navigate(["/candidate/" + APP_URLS.candidate.login]);
    }
  }
  
  forgotPassword(payload: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "",
      }),
    };
    return this.http.post(
      `${environment.baseUrl}/candidate/forgotPassword`,
      payload,
      httpOptions
    );
  }

  // Activate Account Saheer
  activateAccount(payload: any): Observable<any> {
    if (payload.type == "candidate") {
      return this.http.get(
        `${environment.baseUrl}/${payload.type}/activateAccount?token=${payload.token}&email=${payload.email}`
      );
    } else {
      return this.http.get(
        `${environment.baseUrl}/${payload.type}/auth/activateAccount?token=${payload.token}&email=${payload.email}`
      );
    }
  }

  // login & signup using linkedIn
  // : Observable<any>
  linkedinLoginOrSignup(code) {
    const header = {
      headers: new HttpHeaders().set("Authorization", `${code}`),
    };
    let url = "";
    if (localStorage.getItem("linkedIn") == "login") {
      url = routes.get("candidate.linkedInLogin");
    }
    if (localStorage.getItem("linkedIn") == "signup") {
      url = routes.get("candidate.linkedInSignup");
    }
    return window.fetch(url, {
      method: "POST",
      headers: { Authorization: code },
    });
  }

  // login & signup using google
  googleLoginOrSignup(code) {
    const header = {
      headers: new HttpHeaders().set("Authorization", `${code}`),
    };
    let url = "";
    if (localStorage.getItem("google") == "login") {
      url = routes.get("candidate.googleLogin");
    }
    if (localStorage.getItem("google") == "signup") {
      url = routes.get("candidate.googleSignup");
    }
    return window.fetch(url, {
      method: "POST",
      headers: { Authorization: code },
    });
  }
}
