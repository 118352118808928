import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-mand-ques-popup",
  templateUrl: "./mand-ques-popup.component.html",
  styleUrls: ["./mand-ques-popup.component.scss"],
})
export class MandQuesPopupComponent implements OnInit {
  @Input() incomingData;
  @Input() name;
  @Output() questionsCompleted = new EventEmitter();
  @Output() close = new EventEmitter();

  questions = [];
  answers = [];
  count: number = 0;
  total: number = null;

  form = this.fb.group({
    ans: [false, [Validators.required]],
  });

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.questions = this.incomingData;
    this.total = this.questions.length;
  }

  get ans() {
    return this.form.controls.ans;
  }

  formSubmit() {
    this.form.patchValue({
      ans: +this.form.value.ans,
    });
    const obj = {
      q: this.questions[this.count].id,
      a: this.form.value.ans,
    };
    this.answers.push(obj);
    this.form.reset({ ans: false });
    if (this.count === this.total - 1) return this.sendData();
    ++this.count;
  }

  sendData() {
    this.questionsCompleted.emit(this.answers);
  }

  closePopup() {
    this.close.emit({ close: true });
  }
}
