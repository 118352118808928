import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { CandidateAuthenticationService } from "src/app/services/candidate/auth/candidate-authentication.service";
import { MessagesService } from "src/app/services/candidate/messages/messages.service";
import { RightSidebarService } from "src/app/services/candidate/right-sidebar/right-sidebar.service";
import { LandingPageService } from "src/app/services/marketing/landing/landing-page.service";
import { v4 as uuidv4 } from 'uuid'

@Component({
  selector: "app-c-left-sidebar",
  templateUrl: "./c-left-sidebar.component.html",
  styleUrls: ["./c-left-sidebar.component.scss"],
})
export class CLeftSidebarComponent implements OnInit {
  mobMenuToggle: boolean = false;
  notificationUnreadCounter = 0
  messageUnreadCounter = 0

  // routerUrl:any="";
  @Input() routerUrl: any;
  constructor(
    public router: Router,
    private authenticationService: CandidateAuthenticationService,
    private userCount: LandingPageService,
    private messageService: MessagesService,
    private sidebarService: RightSidebarService,
  ) { }
  ngOnInit(): void {
    this.messageService.getMessageList().subscribe((messages) => {
      
      var count = 0
      for (var i = 0; i < messages.data.length; i++) {
        if (messages.data[i].unread_message_count > 0) {
          count++
        }
      }
      this.messageUnreadCounter = count
    });

    this.sidebarService.getNotifications(0, 20).subscribe(
      (res) => {
        
        var count = 0
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].read_status == 0) {
            count++
          }
        }
        this.notificationUnreadCounter = count
      },
      (err) => {

      }
    );
  }
  showMeHandler() { }
  goToCandidateHome() {
    this.router.navigate(["/"]);
  }
  logoutAndRefresh() {
    var logout = confirm("Are you sure you want to logout?");
    if (logout) {
      this.authenticationService.doLogout();
      const sessionId = uuidv4(); // Generate a unique session ID
      if (!sessionStorage.getItem('sessionId')) {
        sessionStorage.setItem('sessionId', sessionId); // Store the session ID in sessionStorage
      }
      this.userCount.logOutInUser();
      this.userCount.visitorIn();
      // this.socket.emit('logout', 'logout')
      // this.socket.emit('visitor', 'visitor')
      this.router.navigate(["/"]);
    }
  }
}
