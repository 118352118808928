import { environment } from "./../environments/environment";

const ENDPOINTS = {
  recruiter: {
    requestPath: "recruiter",
    paths: {
      login: "auth/login",
      register: "auth/register",
      dashboard: "dashboard",
      accountInfo: "account",
      paymentHistory: "account/paymentHistory",
      update_company_info: "account/updateCompanyInfo",
      update_job: "job/updateJobInfo",
      get_job_info: "job/info",
      dashboardApplicantData: "dashboard/getDashboardApplicantData",
      subscription_plan_list: "subscription/getPlans",
      messagesList: "message",
      add_order: "order/addOrder",
      drop_down: "job/dropdownList",
      notificationsList: "notification",
      removeAllNotifications: "notification/removeAll",
      userMessages: "message/getUserMessages",
      sendMessage: "message/sentMessage",
      removeMessage: "message/removeMessage",
      reset_password: "account/resetPassword",
      jobs: "job/getJobList",
      jobs_locations: "job/getLocation",
      jobs_countries: "job/getCountries",
      jobs_state: "job/getStates",
      jobs_cities: "job/getCities",
      jobs_industries: "job/getIndustriesByFilter",
      jobs_statuses: "job/getAllStatus",
      add_job: "job/add",
      jobs_type: "job/getJobTypes",
      jobs_skills: "job/getSkillByFilter",
      change_logo: "account/changeLogo",
      jobs_candidate_applicationList: "candidate/getApplicantList",
      jobs_candidate_shortlistCandidate: "candidate/shortListCandidate",
      jobs_candidate_shortlistedApplicants: "candidate/getShortListApplicants",
      jobs_candidate_changeApplicantStatus: "candidate/changeApplicantStatus",
      jobs_candidate_getSearchCandidateLocation:
        "candidate/getSearchCandidateLocation",
      jobs_candidate_searchCandidate: "candidate/searchCandidate",
      searchResumes: "candidate/list",
      getLocations: "candidate/getSearchCandidateLocation",
      dropdowns: "candidate/getBasicDropDownOptions",
      getDesignations: "candidate/getAvailableDesignation",
      getEducation: "candidate/getAvailableEducation",
      getSkills: "candidate/getSkills",
      getProfileTitles: "candidate/getProfileTitles",
      getAllSkills: "candidate/getAllSkills",
      getEmployer: "candidate/getSearchCurrentEmployer",
      reportsAllStatus: "job/getAllStatus",
      reportsJobList: "candidate/getMyJobList",
      reportsIndustries: "job/getIndustriesByFilter",
      reportsLocation: "candidate/getSearchCandidateLocation",
      reportsCountries: "job/getCountries",
      reportsApplication: "report/applicationsData",
      reportsApplicationDownload: "report/applications",
      plans: "subscription/getPlans",
      getActiveJobListForEmail: "job/getActiveJobListForEmail",
      getJobDetailsEmail: "job/getJobDetailsEmail",
      sendMailToSelectedCandidates: "email/sendMailToSelectedCandidates",
      sendMailToAllCandidates: "email/sendMailToAllCandidates",
    },
  },
  home: {
    requestPath: "vjob",
    paths: {
      exp_drop_down: "getExperienceList", 
      getFilterOptions: "getFilterOptions",
      testimonials: "getTestimonials",
      companies: "getCompanies",
      dashboard: "dashboard",
      getLocation: "getLocation",
      getJobs: "getJobs",
      getFilterJobTitles: "getFilterJobTitles",
      getFilterJobLocation: "getFilterJobLocation",
      getFilterIndutries: "getFilterIndutries",
      getFilterCompanies: "getFilterCompanies",
      getJobDetails: "getJobDetails",
      getActiveIndustries: "getActiveIndustries",
      getIndustries: "getIndustries",
      getCountryIndustryList: "getCountryIndustryList",
      getCompaniesJobs: "getCompaniesJobs",
      contactUs: "contactUs",
      partners: "getWebsitePartnerCompany",
      getCountryAndCities: "getCountryAndCities",
      getQuickLinks: "pages",
      nonServicePage: "static-pages",
      servicePage: "pages",
      visitorOutCount: "visitorOutCount",
      visitorInCount: "visitorInCount",
      logOutUserCount: "logOutUserCount",
      logInUserCount: "logInUserCount"
    },
    login: "auth/login",
    register: "auth/register",
    dashboard: "dashboard",
    accountInfo: "account",
    dashboardApplicantData: "dashboard/getDashboardApplicantData",
    messagesList: "message",
    notificationsList: "notification",
    removeAllNotifications: "notification/removeAll",
    userMessages: "message/getUserMessages",
    sendMessage: "message/sentMessage",
    jobs: "job/getJobList",
    jobs_locations: "job/getLocation",
    jobs_countries: "job/getCountries",
    jobs_industries: "job/getIndustriesByFilter",
    jobs_statuses: "job/getAllStatus",
    jobs_skills: "job/getSkillByFilter",
    jobs_candidate_applicationList: "candidate/getApplicantList",
    jobs_candidate_shortlistCandidate: "candidate/shortListCandidate",
    jobs_candidate_shortlistedApplicants: "candidate/getShortListApplicants",
    jobs_candidate_changeApplicantStatus: "candidate/changeApplicantStatus",
    searchResumes: "candidate/list",
    getLocations: "candidate/getSearchCandidateLocation",
    dropdowns: "candidate/getBasicDropDownOptions",
    getDesignations: "candidate/getAvailableDesignation",
    getEducation: "candidate/getAvailableEducation",
    getSkills: "candidate/getSkills",
  },
  candidate: {
    requestPath: "candidate",
    paths: {
      login: "login",
      register: "register",
      dashboard: "dashboard",
      accountInfo: "account",
      paymentHistory: "account/paymentHistory",
      update_company_info: "account/updateCompanyInfo",
      update_job: "job/updateJobInfo",
      get_job_info: "job/info",
      dashboardApplicantData: "dashboard/getDashboardApplicantData",
      subscription_plan_list: "subscription/getPlans",
      messagesList: "message",
      add_order: "order/addOrder",
      drop_down: "job/dropdownList",
      notificationsList: "notification",
      removeAllNotifications: "notification/removeAll",
      userMessages: "message/getUserMessages",
      sendMessage: "message/sentMessage",
      reset_password: "account/resetPassword",
      jobs: "job/getJobList",
      jobs_locations: "job/getLocation",
      jobs_countries: "job/getCountries",
      jobs_industries: "job/getIndustriesByFilter",
      jobs_statuses: "job/getAllStatus",
      add_job: "job/add",
      jobs_type: "job/getJobTypes",
      jobs_skills: "job/getSkillByFilter",
      change_logo: "account/changeLogo",
      save_job: "job/savejob",
      jobs_candidate_cancelApplication: "job/cancelApplication",
      jobs_candidate_applicationList: "candidate/getApplicantList",
      jobs_candidate_shortlistCandidate: "candidate/shortListCandidate",
      jobs_candidate_shortlistedApplicants: "candidate/getShortListApplicants",
      jobs_candidate_changeApplicantStatus: "candidate/changeApplicantStatus",
      jobs_candidate_getSearchCandidateLocation:
        "candidate/getSearchCandidateLocation",
      jobs_candidate_searchCandidate: "candidate/searchCandidate",
      searchResumes: "candidate/list",
      getLocations: "candidate/getSearchCandidateLocation",
      dropdowns: "candidate/getBasicDropDownOptions",
      getDesignations: "candidate/getAvailableDesignation",
      getEducation: "candidate/getAvailableEducation",
      getSkills: "candidate/getSkills",
      getEmployer: "candidate/getSearchCurrentEmployer",
      reportsAllStatus: "job/getAllStatus",
      reportsJobList: "candidate/getMyJobList",
      reportsIndustries: "job/getIndustriesByFilter",
      reportsLocation: "candidate/getSearchCandidateLocation",
      reportsCountries: "job/getCountries",
      reportsApplication: "report/applicationsData",
      reportsApplicationDownload: "report/applications",
      applyJob: "job/applyJob",
      myjob_getJobDetails: "myjob/getJobDetails",
      linkedInLogin: "loginWithLinkedInWeb",
      linkedInSignup: "registerWithLinkedInWeb",
      googleLogin: "loginWithGoogleWeb",
      googleSignup: "registerWithGoogle",
    },
  },
  workforce: {
    requestPath: "workforce",
    paths: {
      clientOpList: "clientOpList",
      city: "city",
      clientRegistration: "clientRegistration",
      supplierOpList: "supplierOpList",
      supplierRegistration: "supplierRegistration",
      enquiryOpList: "enquiryOpList",
      enquiryRegistration: "enquiryRegistration",
    },
  },
  admin: {
    requestPath: "admin",
    paths: {
      login: "login",
      "candidate/register": "candidate/register",
    },
  },
};

export function get(path: string) {
  const paths = path.split(".");
  if (paths.length !== 2) {
    return null;
  }
  const mainPath = ENDPOINTS[paths[0]];
  return `${environment.baseUrl}/${mainPath.requestPath}/${mainPath.paths[paths[1]]
    }`;
}

const APP_URLS = {
  employer: {
    plan: "plan",
    login: "login",
    dashboard: "dashboard",
    accountInfo: "account",
    signup: "signup",
    jobs: "jobs",
    jobs_newJob: "new",
    updatejob: "updatejob",
    viewjob: "viewjob",
    applications: "applications",
    shortlisted: "shortlisted",
    reports: "reports",
    messenger: "messenger",
    notifications: "notifications",
    myaccount: "myaccount",
    finder_list: "results",
    finder_form: "finder",
    reset_password: "resetPassword",
    view_resume: "viewResume",
    send_email: "email",
  },
  candidate: {
    login: "login",
    dashboard: "dashboard",
    accountInfo: "account",
    signup: "signup",
    register: "register",
    jobs: "jobs",
    job: "job",
    jobView: "jobView",
    jobs_newJob: "new",
    updatejob: "updatejob",
    applications: "applications",
    shortlisted: "shortlisted",
    reports: "reports",
    messenger: "messenger",
    notifications: "notifications",
    myaccount: "myaccount",
    finder_list: "results",
    finder_form: "finder",
    reset_password: "resetPassword",
    view_resume: "viewResume",
    my_applications: "myApplications",
    saved_applications: "savedApplications",
    my_resume: "myResume",
  },
  plan: "plan",
  updatejob: "updatejob",
  viewjob: "viewjob",
  login: "login",
  dashboard: "dashboard",
  accountInfo: "account",
  signup: "signup",
  jobs: "jobs",
  jobs_newJob: "new",
  finder_list: "results",
  finder_form: "finder",
  applications: "applications",
  shortlisted: "shortlisted",
  reports: "reports",
  messenger: "messenger",
  notifications: "notifications",
  myaccount: "myaccount",
};

export { APP_URLS };
