import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-page-not-found",
  // templateUrl: "./page-not-found.component.html",
  template: `<app-c-home-heder></app-c-home-heder>
    <div class="error-block">
      <div class="error">
        <p class="title">404 Page Not Found!!</p>
        <p class="desc">The page you requested does not exist.</p>
      </div>
    </div>
    <app-c-home-footer></app-c-home-footer>`,
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
