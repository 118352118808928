import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language=this.getDefaultLanguage() //'en' or 'arb'
  constructor(
    private translateService:TranslateService
  ) { }
  changeLanguage(){
    localStorage.setItem("currentLanguage",this.language)
    this.translateService.use(this.language)
  }
  getDefaultLanguage(){
    return localStorage.getItem("currentLanguage")?localStorage.getItem("currentLanguage"):"en"
  }
}
