import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CandidateAuthenticationService } from "../auth/candidate-authentication.service";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RightSidebarService {
  httpOptions: any;
  notificationData = new BehaviorSubject(null);
  notificationDataObs$ = this.notificationData.asObservable();
  constructor(
    private http: HttpClient,
    private auth: CandidateAuthenticationService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
  }
  getMessages(start = 0, limit = 5): Observable<any> {
    this.httpOptions["params"] = { start: start, limit: limit };
    return this.http.get(
      `${environment.baseUrl}/candidate/message`,
      this.httpOptions
    );
  }
  getNotifications(start = 0, limit = 2): Observable<any> {
    this.httpOptions["params"] = { start: start, limit: limit };
    return this.http.get(
      `${environment.baseUrl}/candidate/notification`,
      this.httpOptions
    );
  }

  onNotifCompDestroy() {
    this.notificationData.next(true);
  }
}
