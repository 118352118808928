import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CandidateAuthenticationService } from "../auth/candidate-authentication.service";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { isDevMode } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MyAccountService {
  httpOptions: any;

  // bug fixed
  private profilePic = new Subject();

  sendUpdate(profilePicUrl: string) {
    this.profilePic.next({ url: profilePicUrl });
  }

  getUpdate(): Observable<any> {
    return this.profilePic.asObservable();
  }

  constructor(
    private http: HttpClient,
    private auth: CandidateAuthenticationService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
  }

  getAccountInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/account/getAccountInfo`,
      this.httpOptions
    );
  }

  changeAccountName(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/changeAccountName`,
      payload,
      this.httpOptions
    );
  }

  changeEmailId(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/changeEmailId`,
      payload,
      this.httpOptions
    );
  }

  ChanegeMobileNumber(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/ChanegeMobileNumber`,
      payload,
      this.httpOptions
    );
  }
  changeProfilePic(payload): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    return this.http.post(
      `${environment.baseUrl}/candidate/account/changeProfilePic`,
      payload,
      httpOptions
    );
  }

  resetPassword(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/resetPassword`,
      payload,
      this.httpOptions
    );
  }
  sendEmailOtp(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/sendEmailOtp`,
      payload,
      this.httpOptions
    );
  }
  validateEmailOtp(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/validateEmailOtp`,
      payload,
      this.httpOptions
    );
  }
  sendMobileOtp(payload): Observable<any> {
    // if(isDevMode()) {
    //       payload.isDemo = true
    //     }
    return this.http.post(
      `${environment.baseUrl}/candidate/account/sendOtp`,
      payload,
      this.httpOptions
    );
  }
  validateMobileOtp(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/account/validateOtp`,
      payload,
      this.httpOptions
    );
  }
  getOtpCountryList(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/mandatoryReg/otpCountryList`,
      this.httpOptions
    );
  }
}
