import { Component, HostListener } from "@angular/core";
import { EmployerAuthenticationService } from "./services/employer/auth/employer-authentication.service";
import { NavigationEnd, Router } from "@angular/router";
import { APP_URLS } from "./routes";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./services/language.service";
import { CandidateAuthenticationService } from "./services/candidate/auth/candidate-authentication.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
// import { global } from "@angular/compiler/src/util";
import { environment } from "src/environments/environment";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { SwUpdate, ServiceWorkerModule } from "@angular/service-worker";
import { v4 as uuidv4 } from 'uuid'
import { LandingPageService } from "./services/marketing/landing/landing-page.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if (localStorage.getItem('employer_auth') || localStorage.getItem('Ctoken')) {
      this.userCount.logOutInUser();
    }
    else {
      this.userCount.visitorOut();
    }
  }
  constructor(
    public employerAuthService: EmployerAuthenticationService,
    public candidateAuthenticationService: CandidateAuthenticationService,
    public router: Router,
    private translate: TranslateService,
    private languageService: LanguageService,
    private gtmService: GoogleTagManagerService,
    public sanitizer: DomSanitizer,
    private userCount: LandingPageService,
    private meta: Meta,
    private swUpdate: SwUpdate
  ) {
    // META INFO(RESOLUTION FOR THE LINK SHARING BUG)
    this.meta.addTag({ property: 'og:title', content: 'Hires Gulf' });
    this.meta.addTag({ property: 'og:image', content: 'assets/img/logo.png' });
    // this.meta.addTag({ property: 'og:description', content: '' });
    // this.meta.addTag({ property: 'og:url', content: '' });

    // private gtmService: GoogleTagManagerService,
    // this.router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };
    //     this.gtmService.pushTag(gtmTag);
    //   }
    // });

    translate.setDefaultLang(languageService.getDefaultLanguage());
    let path = window.location.pathname;

    if (path === "/employer/login" && employerAuthService.isLoggedIn()) {
      router.navigateByUrl("employer/" + APP_URLS.employer.dashboard);
    }
    if (
      candidateAuthenticationService.isAuthenticated() &&
      path.includes("/candidate/login")
    ) {
      router.navigateByUrl("candidate/" + APP_URLS.candidate.dashboard);
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('sw.js')
        .then(function () { console.log('Service Worker Registered'); });
    }

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    })
  }
  // constructor(private translate: TranslateService) {
  //       translate.setDefaultLang('en');
  //   }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          localStorage.setItem('updateAvailable', 'true');
        }
      });
    }
    this.languageService.changeLanguage();
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        let resetcss = document.getElementById("resetcss");
        let path = window.location.pathname;

        if (!resetcss) {
          document
            .getElementsByTagName("head")[0]
            .insertAdjacentHTML(
              "beforeend",
              '<link rel="stylesheet" href="restcss.css" media="all" id="resetcss"/>'
            );
        }
        resetcss = document.getElementById("resetcss");
        // console.log("this.router.url.includes(", path, "rescss", resetcss);

        if ((path.includes("/post") || path.includes("/service")) && resetcss) {
          resetcss.remove();
        }

        //for including collect chat code starts
        if (
          route.url.includes("register") ||
          route.url.includes("signup") ||
          route.url.includes("login")
        ) {
          if (!route.url.includes("admin")) {
            (function (w, d) {
              const globalAny: any = globalThis;
              globalAny.CollectId = environment.ccId;
              var h = d.head || d.getElementsByTagName("head")[0];
              var s = d.createElement("script");
              s.setAttribute("type", "text/javascript");
              s.async = true;
              s.setAttribute("src", "https://collectcdn.com/launcher.js");
              s.id = "collectChatScript";
              h.appendChild(s);
            })(window, document);
          }
        }

        if (
          !route.url.includes("register") &&
          !route.url.includes("signup") &&
          !route.url.includes("login")
        ) {
          if (document.getElementById("chat-bot-launcher-container")) {
            let ccBox = document.getElementById("chat-bot-launcher-container");
            ccBox.parentNode.removeChild(ccBox);
          }
          // let head = document.getElementsByTagName("head")[0];
          // let elem = document.getElementById("collectChatScript");
          // head.removeChild(elem);
          // console.log(head);
        }
        if (route.url.includes("admin")) {
          if (document.getElementById("chat-bot-launcher-container")) {
            let ccBox = document.getElementById("chat-bot-launcher-container");
            ccBox.parentNode.removeChild(ccBox);
          }
        }
        //for including collect chat code ends
      }
    });

    //for getting the count of active users and visitors
    const sessionId = uuidv4(); // Generate a unique session ID

    //When visitors come
    if (!(localStorage.getItem('Ctoken') || localStorage.getItem('employer_auth')) && !sessionStorage.getItem('sessionId')) {
      sessionStorage.setItem('sessionId', sessionId); // Store the session ID in sessionStorage
      this.userCount.visitorIn();
      // this.socket.emit('visitor', sessionId);
    }
    //when a user is logged in but opens a new tab
    else if ((localStorage.getItem('Ctoken') || localStorage.getItem('employer_auth')) && !sessionStorage.getItem('sessionId')) {
      sessionStorage.setItem('sessionId', sessionId); // Store the session ID in sessionStorage
      this.userCount.logInUser();
      // this.socket.emit('login', sessionId);
    }
  }
  title = "horizon-angular";

  isEmployerRoute() {
    return this.router.url.includes("/employer");
  }
  // isCandidateRoute() {
  //   return this.router.url.includes("/candidate");
  // }
}
