import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from '@angular/material/menu';
import { CHomeHederComponent } from "./c-home-heder/c-home-heder.component";
import { CAppBannerComponent } from "./c-app-banner/c-app-banner.component";
import { CHomeFooterComponent } from "./c-home-footer/c-home-footer.component";
import { ShareJobPopupComponent } from "./share-job-popup/share-job-popup.component";
import { LanguageChangeModule } from "../common-modules/language-change/language-change.module";
import { HttpLoaderFactory } from "../app.module";
import { HttpClient } from "@angular/common/http";
import { CLeftSidebarComponent } from "./c-left-sidebar/c-left-sidebar.component";
import { CRightSidebarComponent } from "./c-right-sidebar/c-right-sidebar.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MandQuesPopupComponent } from "./mand-ques-popup/mand-ques-popup.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ResetPassPopupComponent } from "./reset-pass-popup/reset-pass-popup.component";
import { MessagePopupComponent } from "./message-popup/message-popup.component";
import { environment } from "src/environments/environment";
@NgModule({
  declarations: [
    CAppBannerComponent,
    CHomeHederComponent,
    CHomeFooterComponent,
    ShareJobPopupComponent,
    CLeftSidebarComponent,
    CRightSidebarComponent,
    MandQuesPopupComponent,
    ResetPassPopupComponent,
    MessagePopupComponent,
  ],
  imports: [
    LanguageChangeModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxSkeletonLoaderModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,

  ],
  exports: [
    CAppBannerComponent,
    CHomeHederComponent,
    CHomeFooterComponent,
    ShareJobPopupComponent,
    CLeftSidebarComponent,
    CRightSidebarComponent,
    LanguageChangeModule,
    MandQuesPopupComponent,
    ResetPassPopupComponent,
    MessagePopupComponent,
  ],
})
export class SharedCandidateModule { }
