import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CandidateApisService } from "src/app/services/candidate/apis/candidate-apis.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-share-job-popup",
  templateUrl: "./share-job-popup.component.html",
  styleUrls: ["./share-job-popup.component.scss"],
})
export class ShareJobPopupComponent implements OnInit {
  @Input() title;
  @Input() id;
  @Output() popupclose = new EventEmitter();
  jobLink: string = "";
  constructor(
    private api: CandidateApisService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.jobLink = `${this.api.baseUrlOfApp}/job?jobId=${this.id}`;
  }

  closePopup() {
    this.popupclose.emit({ close: true, updated: false });
  }
  copyJobLink(jobInputBox) {
    jobInputBox.select();
    // jobInputBox.setSelectionRange(0, 99999);
    document.execCommand("copy");
    this.snackBar.open("Link copied to clipboard!", `×`);
  }
}
