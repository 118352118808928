import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CandidateAuthenticationService } from "../auth/candidate-authentication.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MyResumeService {
  httpOptions: any;

  // Bug Fixed: Gender profile pic not changing after
  // changing gender to male or female.
  private pictureSubject = new Subject();

  sendGenderedPicUpdate(isGenderChanged: Boolean) {
    this.pictureSubject.next({ isGenderChanged: isGenderChanged });
  }

  sendProfilePicUpdate(profilePic: string) {
    this.pictureSubject.next({ profilePic: profilePic });
  }

  getPicUpdate(): Observable<any> {
    return this.pictureSubject.asObservable();
  }
  // end...

  constructor(
    private http: HttpClient,
    private auth: CandidateAuthenticationService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
  }
  getBasicInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getBasicInfo`,
      this.httpOptions
    );
  }
  getOtpCountryList(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/mandatoryReg/otpCountryList`,
      this.httpOptions
    );
  }
  updateBasicInfo(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/resume/updateBasicInfo`,
      payload,
      this.httpOptions
    );
  }
  getEducationInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/education/getEducationsInfo`,
      this.httpOptions
    );
  }
  addEducationInfo(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/education/addEducationInfo`,
      payload,
      this.httpOptions
    );
  }
  updateEducationInfo(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/education/updateEducationInfo`,
      payload,
      this.httpOptions
    );
  }
  getExperienceInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/work/getWorksExperienceInfo`,
      this.httpOptions
    );
  }
  addWorkExperience(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/work/addWorkExperience`,
      payload,
      this.httpOptions
    );
  }
  updateExperienceInfo(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/work/updateExperienceInfo`,
      payload,
      this.httpOptions
    );
  }
  getProjectsInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getProjectsInfo`,
      this.httpOptions
    );
  }
  addProjectInfo(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/resume/addProjectInfo`,
      payload,
      this.httpOptions
    );
  }
  updateProjectInfo(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/resume/updateProjectInfo`,
      payload,
      this.httpOptions
    );
  }
  getAttachmentsInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getAttachmentsInfo`,
      this.httpOptions
    );
  }
  addAttachment(payload): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    return this.http.post(
      `${environment.baseUrl}/candidate/resume/updateAttachement`,
      payload,
      httpOptions
    );
  }
  getSkillsInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/profile/getSkills`,
      this.httpOptions
    );
  }
  getAdminSkillsList(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/profile/getAllSkills`,
      this.httpOptions
    );
  }
  addSkill(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/profile/addSkill`,
      payload,
      this.httpOptions
    );
  }
  removeSkill(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/profile/removeSkill`,
      payload,
      this.httpOptions
    );
  }
  removeCertificate(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/resume/removeCertificate`,
      payload,
      this.httpOptions
    );
  }
  getCertificateInfo(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getCertificatesInfo`,
      this.httpOptions
    );
  }
  addCertificationInfo(payload): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    return this.http.post(
      `${environment.baseUrl}/candidate/resume/addCertificationInfo`,
      payload,
      httpOptions
    );
  }

  getDropDownList(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getBasicDropDownOptions`,
      this.httpOptions
    );
  }

  getDropDownListWithoutLogin(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/vjob/getCountryAndCities`,
      this.httpOptions
    );
  }

  getEduDropdown(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getEducationOptionsList`,
      this.httpOptions
    );
  }

  getEduTypeDropdown(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getEducationTypeOptionsList`,
      this.httpOptions
    );
  }

  deleteAttachement(payload): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}/candidate/resume/deleteAttachement`,
      payload,
      this.httpOptions
    );
  }

  getCurrencyList(): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/vjob/getCurrencies`,
      this.httpOptions
    );
  }

  downloadCv(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/candidate/profile/exportCv`, {
      observe: "response",
      responseType: "arraybuffer",
    });
  }

  getStates(id: number): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getStates?country_id=${id}`,
      this.httpOptions
    );
  }
  getCities(id: number): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}/candidate/resume/getCities?state_id=${id}`,
      this.httpOptions
    );
  }
}
