import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { CandidateApisService } from "src/app/services/candidate/apis/candidate-apis.service";

@Component({
  selector: "app-reset-pass-popup",
  templateUrl: "./reset-pass-popup.component.html",
  styleUrls: ["./reset-pass-popup.component.scss"],
})
export class ResetPassPopupComponent implements OnChanges {
  @Input() data: number;
  showPopup: boolean = true;
  isReset: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apis: CandidateApisService,
    private snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes) {
    if (sessionStorage.getItem("skipResetPass")) return;
    if (changes.data.firstChange == false) {
      this.isReset = this.data;
      if (this.isReset == 0) document.body.style.overflow = "hidden";
    }
  }

  closePopup() {
    this.showPopup = false;
    document.body.style.overflow = "unset";
  }

  onClickOk() {
    this.router.navigate(["../myaccount"], {
      relativeTo: this.route,
      queryParams: { from: "resetPass" },
    });
  }

  onClickSkip() {
    this.apis.skipResetPass().subscribe(
      (res) => {
        this.closePopup();
      },
      (err) => {
        this.snackBar.open(err.message, "X");
      }
    );
  }
}
